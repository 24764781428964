import { createAction, createReducer } from '../../helpers';
import { baseUrl } from '../../../constants/api';
import axios from 'axios';
import Cookies from 'js-cookie';

/** Action types **/
const SET_ADMIN_USERS = 'SET_ADMIN_USERS';
const SET_USERS_LOADING = 'SET_USERS_LOADING';

/** Actions **/

export const setAdminUsers = createAction(SET_ADMIN_USERS);
export const setUsersLoading = createAction(SET_USERS_LOADING);

export function getAdminUsers() {
    return (dispatch) => {
        dispatch(setUsersLoading(true));
        axios({
            method: 'post',
            url: baseUrl.concat('/users/getAdminUsers'),
            headers: {
                authorization: Cookies.get('token'),
            },
        })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setAdminUsers(res.data.data));
                }
                dispatch(setUsersLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(setUsersLoading(false));
            });
    };
}
export function updateAdminUsers(data) {
    return (dispatch) => {
        axios
            .post(baseUrl + '/users/updateAdminUsers', data, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(getAdminUsers());
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch(setUsersLoading(false));
            });
    };
}

/** Reducer **/
export const adminUsersTableData = createReducer({ adminUsers: [] }, (state, { value }) => ({
    [SET_ADMIN_USERS]: () => {
        const adminUsers = value.map((item) => {
            return { ...item, key: item.id };
        });
        return { count: value.count, adminUsers };
    },
    [SET_USERS_LOADING]: () => ({ ...state, isLoading: value }),
    // [UPDATE_EXAMPLE]: { ...state, ...value },
    // [RESET_EXAMPLE]: initial,
}));
