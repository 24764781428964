import React, { useEffect } from 'react';
import { Modal, Button, Table } from 'antd';

function ShippingDetailsModal({ visible, onCloseModal, SDData }) {
    const tableColumns = [
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: '1',
        },
        {
            title: 'Lasts Name',
            dataIndex: 'last_name',
            key: '2',
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: '3',
        },
        {
            title: 'Street Address',
            dataIndex: 'streetAddress',
            key: '4',
        },
        {
            title: 'Apt',
            dataIndex: 'apt',
            key: '5',
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: '6',
        },
        {
            title: 'Post Code',
            dataIndex: 'postCode',
            key: '7',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: '8',
        },
    ];

    const data = [];

    !!SDData &&
        !!SDData.first_name &&
        [SDData].forEach((item) => {
            data.push({ ...item, key: item.id });
        });
    return (
        <Modal
            title="Shipping Details"
            visible={visible}
            onCancel={() => {
                onCloseModal();
            }}
            footer={[
                <Button key="back" onClick={onCloseModal}>
                    Back
                </Button>,
            ]}
        >
            <Table
                pagination={false}
                columns={tableColumns}
                dataSource={data.length > 0 ? data : null}
                bordered
                loading={false}
                indentSize={15}
                rowKey={(record) => record.id}
            />
        </Modal>
    );
}

export default ShippingDetailsModal;
