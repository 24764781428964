import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';

function OrderProductTab({ data }) {
    const tableColumns = [
        {
            title: 'Product ID',
            dataIndex: 'product_id',
            key: '0',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
            render: (text, row) => (
                <span style={{ marginRight: 16 }}>
                    <Link to={'/products/' + row.product_id}>{row.product_id}</Link>
                </span>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'title_am',
            key: '1',
        },
        {
            title: 'Price',
            dataIndex: 'price_single',
            key: '2',
        },
        {
            title: 'Product count',
            dataIndex: 'product_count',
            key: '3',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: '4',
            render: (text, row) => <span style={{ marginRight: 16 }}>{row.price_single * row.product_count}</span>,
        },
    ];

    return (
        <>
            {' '}
            {!!data && (
                <Table
                    pagination={{
                        hideOnSinglePage: true,
                    }}
                    hideOnSinglePage
                    columns={tableColumns}
                    dataSource={data}
                    bordered
                />
            )}
        </>
    );
}

export default OrderProductTab;
