import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import reducers from './index';
import { isDevelopmentStage } from '../helpers/general';

let compose = isDevelopmentStage() ? composeWithDevTools : (param) => param;

const store = createStore(combineReducers(reducers), compose(applyMiddleware(thunkMiddleware)));

window.getState = isDevelopmentStage() && store.getState;

export default store;
