import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Layout, Menu } from 'antd';
import { signOut } from '../../redux/ducks/UserDucks';
import { Button } from 'antd';
import logo from '../../assets/UNIPACK-(13).png';

const { Header } = Layout;

function UniHeader({ signOut }) {
    const history = useHistory();

    const [cookie, setCookie] = useCookies(['token']);

    const removeUserData = () => {
        signOut();
    };
    return (
        <Header style={{ zIndex: 0, width: '100%', justifyContent: 'space-between', display: 'flex' }}>
            <div className="logo">
                <img style={{ width: 120 }} src={logo} alt="LOGO" />
            </div>
            <Button
                style={{
                    margin: '15px 20px 0 0',
                }}
                type="text"
                onClick={removeUserData}
            >
                Logout
            </Button>
        </Header>
    );
}

const mapDispatchToProps = {
    signOut,
};

export default connect(null, mapDispatchToProps)(UniHeader);
