import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import ProductsTabs from './ProductsTabs';
import { UploadOutlined, EyeOutlined } from '@ant-design/icons';
import { imageUrl } from '../../../constants/api';
import { SIDEBAR_IDS } from '../../../constants/ids';

function ProductsTableByID({ productsByIDReducer, tabId, match, history }) {
    useEffect(() => {
        const pathName = match.url.split('/')[1].toUpperCase();
        TABLE_CONTENT_CRUDS[SIDEBAR_IDS[pathName]].getSingle(Number(match.params.id));
    }, [match.params.id, tabId]);
    console.log(productsByIDReducer);
    // productsByIDReducer.product.pictures.map((item, index) => {
    //     item.url = imageUrl + item.url
    //     item.uid = `-${index}`;
    // })

    return (
        <div>
            <div>{`products: ${match.params.id}`}</div>
            <Button onClick={() => history.goBack()} type="primary">
                Back
            </Button>
            <ProductsTabs data={productsByIDReducer} id={Number(match.params.id)} />
        </div>
    );
}

const mapStateToProps = ({ productsByIDReducer }) => ({ productsByIDReducer });

export default connect(mapStateToProps)(ProductsTableByID);
