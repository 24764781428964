import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import UserTabs from './UserTabs';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import { SIDEBAR_IDS } from '../../../constants/ids';

function UsersTableByID({ singleUserData, tabId, userData, match, history }) {
    useEffect(() => {
        const pathName = match.url.split('/')[1].toUpperCase();
        TABLE_CONTENT_CRUDS[SIDEBAR_IDS[pathName]].getSingleUser(Number(match.params.id));
    }, [match.params.id, tabId]);
    return (
        <div>
            <div>{`user: ${match.params.id}`}</div>
            <Button onClick={() => history.goBack()} type="primary">
                Back
            </Button>
            <UserTabs data={singleUserData} />
        </div>
    );
}

const mapStateToProps = ({ usersTableData: { singleUserData = {} } }) => ({ singleUserData });

export default connect(mapStateToProps)(UsersTableByID);
