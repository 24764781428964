import React from 'react';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import {
    AppstoreOutlined,
    CodeSandboxOutlined,
    AuditOutlined,
    UserOutlined,
    SettingOutlined,
    MessageOutlined,
    DashboardOutlined,
    PictureOutlined,
    UserSwitchOutlined,
    GiftOutlined,
    CopyrightOutlined,
    GlobalOutlined,
    MobileOutlined,
    CommentOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { SIDEBAR_NAMES } from '../../constants/names';
import { changeTabId } from '../../redux/ducks/TableDataDucks/TableTabIdDucks';

const { SubMenu } = Menu;

MenuItem.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
};
const iconList = [
    <DashboardOutlined />,
    <AppstoreOutlined />,
    <CodeSandboxOutlined />,
    <AuditOutlined />,
    <UserOutlined />,
    <SettingOutlined />,
    <MessageOutlined />,
    <PictureOutlined />,
    <UserSwitchOutlined />,
    <GiftOutlined />,
    <CopyrightOutlined />,
    <MobileOutlined />,
    <CommentOutlined />,
];

function MenuItem({ id, name, icon, ...other }) {
    let path = ('/' + SIDEBAR_NAMES[id].toLowerCase()).replace(' ', '-');
    const history = useHistory();

    const linkToWeb = (to) => {
        history.push(`/banners/${to}`);
    };

    return (
        <div>
            {name !== 'Banners' ? (
                <Link
                    to={{
                        pathname: path,
                        state: { tabId: id },
                    }}
                >
                    <Menu.Item {...other} icon={iconList[icon]} key={id}>
                        {name}
                    </Menu.Item>
                </Link>
            ) : (
                <SubMenu {...other} key={id} icon={iconList[icon]} title={name}>
                    <Menu.Item icon={<GlobalOutlined />} onClick={() => linkToWeb('web')} key={id + 10}>
                        Web
                    </Menu.Item>
                    <Menu.Item icon={<MobileOutlined />} onClick={() => linkToWeb('mobile')} key={id + 11}>
                        Mobile
                    </Menu.Item>
                </SubMenu>
            )}
        </div>
    );
}

const mapDispatchToProps = {
    changeTabId,
};

export default connect(null, mapDispatchToProps)(MenuItem);
