import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SIDEBAR_NAMES } from '../../../constants/names';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import moment from 'moment';
import { Modal, Button, Checkbox, Table } from 'antd';
import { Link } from 'react-router-dom';
import ViewModal from './ViewModal';

ReviewsTable.propTypes = {
    tabId: PropTypes.number.isRequired,
};

const actions = ['Enable', 'Disable'];

function ReviewsTable({ tabId, tableContentData }) {
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [viewData, setViewData] = useState({});

    useEffect(() => {
        TABLE_CONTENT_CRUDS[tabId].get();
    }, [tabId]);

    const { reviews, pageCount, isLoading, currentPage } = tableContentData;

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: '0',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: '1',
            render: (text, row) => (
                <span style={{ marginRight: 16 }}>
                    <Link to={'/users/' + row.user_id}>{row.user_id}</Link>
                </span>
            ),
        },
        {
            title: 'Product ID',
            dataIndex: 'product_id',
            key: '2',
            render: (text, row) => (
                <span style={{ marginRight: 16 }}>
                    <Link to={'/products/' + row.product_id}>{row.product_id}</Link>
                </span>
            ),
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: '3',
        },
        {
            title: 'Comment text',
            dataIndex: 'comment_text',
            key: '4',
            width: '200px',
            ellipsis: true,
        },
        {
            title: 'Is active',
            dataIndex: 'is_active',
            key: '6',
            sorter: (a, b) => a.is_active - b.is_active,
            render: (text, row) => (
                <Checkbox
                    checked={row.is_active}
                    onChange={() => TABLE_CONTENT_CRUDS[tabId].update({ id: row.id, isActive: !row.is_active })}
                />
            ),
        },
        {
            title: 'Create at',
            dataIndex: 'created_at',
            key: '7',
            render: (text, row) => <span>{moment(row.created_at).format('YYYY-MM-DD')}</span>,
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: '8',
            render: (text, row) => (
                <Button type="primary" onClick={() => TABLE_CONTENT_CRUDS[tabId].delete({ id: row.id })}>
                    Delete
                </Button>
            ),
        },
        {
            title: 'View',
            dataIndex: 'view',
            key: '9',
            render: (text, row) => (
                <Button type="primary" onClick={() => openModal(row)}>
                    View
                </Button>
            ),
        },
    ];

    const data = [];

    const closeModal = () => {
        setViewData({});
        setViewModalVisible(false);
    };

    const openModal = (row) => {
        setViewData(row);
        setViewModalVisible(true);
    };

    reviews?.length &&
        reviews.forEach((item) => {
            data.push({ ...item, key: item.id + '' });
        });

    return (
        <div>
            <Table
                pagination={{
                    onChange: (page) => TABLE_CONTENT_CRUDS[tabId].get({ page }),
                    position: ['none', 'bottomRight'],
                    hideOnSinglePage: true,
                    pageSize: 20,
                    total: 20 * pageCount,
                    current: currentPage,
                }}
                hideOnSinglePage
                columns={tableColumns}
                dataSource={data.length > 0 ? data : null}
                bordered
                loading={isLoading}
                indentSize={15}
                rowKey={(record) => record.id}
                title={() => SIDEBAR_NAMES[tabId]}
            />
            <ViewModal viewData={viewData} visible={viewModalVisible} onOk={closeModal} onCancel={closeModal} />
        </div>
    );
}

const mapStateToProps = ({ reviewsTableData }) => ({ tableContentData: reviewsTableData });

export default connect(mapStateToProps)(ReviewsTable);
