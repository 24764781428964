import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { createBrowserHistory } from 'history';

import 'antd/dist/antd.css';

import 'antd/es/date-picker/style/css';

const history = createBrowserHistory();

ReactDOM.render(
    <Router history={history}>
        <Provider store={store}>
            <React.StrictMode>
                <CookiesProvider>
                    <App />
                </CookiesProvider>
            </React.StrictMode>
        </Provider>
    </Router>,
    document.getElementById('root')
);
