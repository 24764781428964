import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import { SIDEBAR_IDS } from '../../../constants/ids';
import OrderTabs from './OrderTabs';

function SingleOrderByID({ ordersTableData, tabId, match, history }) {
    useEffect(() => {
        const pathName = match.url.split('/')[1].toUpperCase();
        TABLE_CONTENT_CRUDS[SIDEBAR_IDS[pathName]].getSingleOrder(match.params.id);
    }, [match.params.id, tabId]);

    const { singleOrderData } = ordersTableData;
    console.log(singleOrderData);
    return (
        <div>
            <div>{`Order: ${match.params.id}`}</div>
            <Button onClick={() => history.goBack()} type="primary">
                Back
            </Button>
            <OrderTabs data={singleOrderData} />
        </div>
    );
}

const mapStateToProps = ({ ordersTableData }) => ({ ordersTableData });

export default connect(mapStateToProps)(SingleOrderByID);
