import { createAction, createReducer } from '../../helpers';
import { baseUrl } from '../../../constants/api';
import axios from 'axios';
import Cookies from 'js-cookie';

/** Action types **/
const SET_GLOBAL_SETTINGS = 'SET_GLOBAL_SETTINGS';
const UPDATE_GLOBAL_SETTINGS = 'UPDATE_GLOBAL_SETTINGS';
const GLOBAL_SETTINGS_LOADING = 'GLOBAL_SETTINGS_LOADING';

/** Actions **/

export const setGlobalSettings = createAction(SET_GLOBAL_SETTINGS);
export const updateGlobalSettings = createAction(UPDATE_GLOBAL_SETTINGS);
export const globalSettingsLoading = createAction(GLOBAL_SETTINGS_LOADING);

export function getGlobalSettings() {
    return (dispatch) => {
        dispatch(globalSettingsLoading(true));
        axios({
            method: 'post',
            url: baseUrl.concat('/configs/getGlobals'),
            headers: {
                authorization: Cookies.get('token'),
            },
        })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setGlobalSettings(res.data.data));
                }
                dispatch(globalSettingsLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(globalSettingsLoading(false));
            });
    };
}

export function updateGS(data) {
    return (dispatch) => {
        axios
            .post(baseUrl + '/configs/update', data, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(getGlobalSettings());
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch(globalSettingsLoading(false));
            });
    };
}

const initialState = { globalSettings: [], isLoading: true };

/** Reducer **/
export const globalSettingsData = createReducer(initialState, (state, { value }) => ({
    [SET_GLOBAL_SETTINGS]: () => {
        return { ...state, globalSettings: value };
    },
    [UPDATE_GLOBAL_SETTINGS]: () => {
        const globalSettings = state.globalSettings.map((el) => {
            if (!!value.config) {
                if (el.config === value.config) {
                    return { ...el, value: { ...el.value, [value.id]: value.value } };
                }
            } else {
                if (el.config === value.id) {
                    return { ...el, value: value.value };
                }
            }
            return el;
        });
        return { ...state, globalSettings };
    },
    [GLOBAL_SETTINGS_LOADING]: () => ({ ...state, isLoading: value }),
    // [UPDATE_EXAMPLE]: ()=> ({ ...state, ...value }),
    // [RESET_EXAMPLE]: ()=> initial,
}));
