import { createAction, createReducer } from '../../helpers';
import { baseUrl } from '../../../constants/api';
import axios from 'axios';
import Cookies from 'js-cookie';

/** Action types **/
const SET_REVIEWS = 'SET_REVIEWS';
const SET_UPDATE_REVIEWS = 'SET_UPDATE_REVIEWS';
const SET_DELETE_REVIEWS = 'SET_DELETE_REVIEWS';
const SET_REVIEWS_LOADING = 'SET_REVIEWS_LOADING';
const SET_PAGE = 'SET_PAGE';
/** Actions **/
export const setPage = createAction(SET_PAGE);
export const setReviews = createAction(SET_REVIEWS);
export const setDeleteReview = createAction(SET_DELETE_REVIEWS);
export const setUpdateReviews = createAction(SET_UPDATE_REVIEWS);
export const setLoadingReviews = createAction(SET_REVIEWS_LOADING);

export function getReviews(data) {
    return (dispatch) => {
        dispatch(setLoadingReviews(true));
        axios({
            method: 'post',
            url: baseUrl.concat('/reviews/getReviews'),
            headers: {
                authorization: Cookies.get('token'),
            },
            data: { active: 'all', page: data?.page || 0 },
        })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setReviews(res.data.data));
                    dispatch(setPage(data.page || 1));
                }
                dispatch(setLoadingReviews(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(setLoadingReviews(false));
            });
    };
}

export function deleteReviews(id) {
    return (dispatch) => {
        dispatch(setLoadingReviews(true));
        axios
            .post(baseUrl + '/reviews/deleteReviews', id, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                console.log('deleteReviews:', res);
                if (!res.data.error) {
                    dispatch(setDeleteReview(id));
                }
                dispatch(setLoadingReviews(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(setLoadingReviews(false));
            });
    };
}

export function updateReviewStatus(data) {
    return (dispatch) => {
        dispatch(setLoadingReviews(true));
        axios
            .post(baseUrl + '/reviews/updateReviews', data, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setUpdateReviews(data));
                }
                dispatch(setLoadingReviews(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(setLoadingReviews(false));
            });
    };
}

/** Reducer **/
export const reviewsTableData = createReducer([], (state, { value }) => ({
    [SET_REVIEWS]: () => {
        const reviews = value.reviews.map((item) => {
            return { ...item, key: item.id };
        });
        return { pageCount: value.pageCount, reviews };
    },
    [SET_UPDATE_REVIEWS]: () => {
        const { reviews } = state;
        reviews.find((row) => row.id === value.id).is_active = value.isActive;
        return { ...state, reviews };
    },
    [SET_REVIEWS_LOADING]: () => ({ ...state, isLoading: value }),
    [SET_PAGE]: () => ({ ...state, currentPage: value }),
    [SET_DELETE_REVIEWS]: () => {
        const { reviews } = state;
        const newReviews = reviews.filter((row) => row.id !== value.id);
        console.log({ ...state, reviews: newReviews });
        return { ...state, reviews: newReviews };
    },
    // [UPDATE_EXAMPLE]: { ...state, ...value },
    // [RESET_EXAMPLE]: initial,
}));
