import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Checkbox } from 'antd';
import { SIDEBAR_NAMES } from '../../../constants/names';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import { SIDEBAR_IDS } from '../../../constants/ids';
import ViewModal from './ViewModal';

function MessagesTable({ tabId, tableContentData, match }) {
    useEffect(() => {
        const pathName = match.url.split('/')[1].toUpperCase();
        TABLE_CONTENT_CRUDS[SIDEBAR_IDS[pathName]].get();
    }, [tabId]);

    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [viewData, setViewData] = useState({});

    const closeModal = () => {
        setViewData({});
        setViewModalVisible(false);
    };

    const deleteMessage = (data) => {
        const pathName = match.url.split('/')[1].toUpperCase();
        TABLE_CONTENT_CRUDS[SIDEBAR_IDS[pathName]].delete(data);
    };

    const openModal = (row) => {
        setViewData(row);
        setViewModalVisible(true);
    };

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: '0',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: '1',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: '2',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: '3',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: '4',
            width: '200px',
            ellipsis: true,
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: '5',
            render: (text, row) => (
                <Button type="primary" onClick={() => deleteMessage({ id: row.id })}>
                    Delete
                </Button>
            ),
        },
        {
            title: 'View',
            dataIndex: 'view',
            key: '6',
            render: (text, row) => (
                <Button type="primary" onClick={() => openModal(row)}>
                    View
                </Button>
            ),
        },
    ];

    const { isLoading, messages, currentPage, count } = tableContentData;
    return (
        <div>
            <Table
                pagination={{
                    onChange: (page) => TABLE_CONTENT_CRUDS[tabId].get({ page }),
                    position: ['none', 'bottomRight'],
                    hideOnSinglePage: true,
                    pageSize: 10,
                    total: count,
                    current: currentPage,
                }}
                hideOnSinglePage
                columns={tableColumns}
                dataSource={messages}
                bordered
                loading={isLoading}
                indentSize={15}
                rowKey={(record) => record.id}
                title={() => SIDEBAR_NAMES[tabId]}
            />
            <ViewModal viewData={viewData} visible={viewModalVisible} onOk={closeModal} onCancel={closeModal} />
        </div>
    );
}

const mapStateToProps = ({ messagesTableData }) => ({
    tableContentData: messagesTableData,
});

const mapDispatchToProps = {
    // updateCompany,
    // setCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesTable);
