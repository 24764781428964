import { createAction, createReducer } from '../../helpers';
import { baseUrl } from '../../../constants/api';
import axios from 'axios';
import Cookies from 'js-cookie';

/** Action types **/
const SET_PARTNERSHIP = 'SET_PARTNERSHIP';
const PARTNERSHIP_LOADING = 'PARTNERSHIP_LOADING';
const SET_PAGE = 'SET_PAGE';

/** Actions **/

export const setPartnership = createAction(SET_PARTNERSHIP);
export const setPage = createAction(SET_PAGE);
export const partnershipLoading = createAction(PARTNERSHIP_LOADING);

export function getPartnership(params = { page: 1 }) {
    return (dispatch) => {
        dispatch(partnershipLoading(true));
        axios
            .post(baseUrl.concat('/cooperation/get'), params, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.data.error && res.data.data.count > 0) {
                    dispatch(setPartnership(res.data.data));
                    dispatch(setPage(params.page || 1));
                }
                dispatch(partnershipLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(partnershipLoading(false));
            });
    };
}

/** Reducer **/
export const partnershipTableData = createReducer({ data: [], isLoading: true }, (state, { value }) => ({
    [SET_PARTNERSHIP]: () => {
        const data = value.cooperation.map((item) => {
            return { ...item, key: item.id };
        });
        return { count: value.count, data };
    },
    [PARTNERSHIP_LOADING]: () => ({ ...state, isLoading: value }),
    [SET_PAGE]: () => ({ ...state, currentPage: value }),
}));
