import React from 'react';
import { Modal, Form, Input, Checkbox, Select, Upload, Button, message } from 'antd';
import { baseUrl, imageUrl } from '../../../constants/api';
import { UploadOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import axios from 'axios';

function EditModal({ visible, onCancelEdit, parentIds, editData, updateCategory }) {
    const [form] = Form.useForm();

    form.setFieldsValue(editData);

    const uploadAction = (options) => {
        const { onSuccess } = options;
        const data = new FormData();
        data.append('icon', options.file);
        data.append('id', editData.id);
        const config = {
            headers: {
                authorization: Cookies.get('token'),
            },
        };
        axios
            .post(options.action, data, config)
            .then((res) => {
                onSuccess(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const picture = {
        name: 'icon',
        action: baseUrl + '/category/update',
        customRequest: uploadAction,
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <Modal
            visible={visible}
            title="Edit a collection"
            okText="Edit"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onCancelEdit();
            }}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        form.resetFields();
                        values.is_active = !!values.is_active; //TODO change to ant design way
                        values.priority = values.priority || 0; //TODO change to ant design way
                        updateCategory(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <>
                <div>Icon :</div>
                <div>
                    <span>
                        <img
                            style={{ width: '150px', margin: '5px 10px 10px 5px' }}
                            alt="example"
                            src={imageUrl + editData.icon}
                        />
                    </span>
                    <Upload {...picture}>
                        <Button>
                            <UploadOutlined /> Upload
                        </Button>
                    </Upload>
                </div>
                <Form form={form} layout="horizontal" name="form_in_modal">
                    <Form.Item name="id" style={{ visibility: 'hidden', height: 0 }}>
                        <Input type={'hidden'} />
                    </Form.Item>
                    <Form.Item
                        name="title_am"
                        label="Name Arm"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the title of armenian!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="title_ru"
                        label="Name Rus"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the title of russian!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="title_en"
                        label="Name Eng"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the title of english!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item name="priority" label="Priority">
                        <Input />
                    </Form.Item>

                    <Form.Item name="is_active" label="Is Active?" valuePropName={'checked'}>
                        <Checkbox checked={false} />
                    </Form.Item>

                    <Form.Item name="parent_id" label="Parent ID">
                        <Select options={parentIds} placeholder="Please select" />
                    </Form.Item>

                    <Form.Item name="menu_section" label="Menu Section">
                        <Select
                            options={[
                                { key: 1, label: 'Group 1', value: 1 },
                                { key: 2, label: 'Group 2', value: 2 },
                                { key: 3, label: 'All', value: 3 },
                            ]}
                            placeholder="Please select"
                        />
                    </Form.Item>
                </Form>
            </>
        </Modal>
    );
}

export default EditModal;
