import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TABLE_CONTENT_CRUDS } from '../../constants/other';
import { SIDEBAR_IDS } from '../../constants/ids';
import SingleGS from './SingleGS';
import { Button, Form, Spin } from 'antd';
import { updateGS } from '../../redux/ducks/TableDataDucks/GlobalSettingsDucks';

function GlobalSettings({ globalSettingsData, updateGS }) {
    useEffect(() => {
        TABLE_CONTENT_CRUDS[SIDEBAR_IDS.GLOBAL_SETTINGS].get();
    }, []);

    const [form] = Form.useForm();
    const { isLoading, globalSettings } = globalSettingsData;
    if (globalSettings.length > 0) {
        form.setFieldsValue(globalSettings);
    }

    const onFormLayoutChange = (info) => {
        form.setFieldsValue(info);
    };
    const onSave = () => {
        form.validateFields()
            .then((values) => {
                form.resetFields();
                updateGS(globalSettings);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <>
            {isLoading ? (
                <Spin />
            ) : (
                <>
                    <Form form={form} layout="horizontal" onValuesChange={(info) => onFormLayoutChange(info)}>
                        {globalSettings.length && (
                            <div style={{ marginLeft: 10 }}>
                                {globalSettings.map((el, i) => (
                                    <SingleGS data={el} key={i} />
                                ))}
                            </div>
                        )}
                    </Form>
                    <Button type="primary" onClick={onSave}>
                        Save
                    </Button>
                </>
            )}
        </>
    );
}

const mapStateToProps = ({ globalSettingsData }) => ({
    globalSettingsData,
});

const mapDispatchToProps = {
    updateGS,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSettings);
