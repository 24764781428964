import { createAction, createReducer } from '../../helpers';
import { baseUrl } from '../../../constants/api';
import axios from 'axios';
import Cookies from 'js-cookie';
import { setSingleUser, setUsersLoading } from './UsersTableDucks';

/** Action types **/
const SET_ORDERS = 'SET_ORDERS';
const SET_PAGE = 'SET_PAGE';
const ORDERS_LOADING = 'ORDERS_LOADING';
const SET_SINGLE_ORDER = 'SET_SINGLE_ORDER';

/** Actions **/

export const setOrders = createAction(SET_ORDERS);
export const setPage = createAction(SET_PAGE);
export const setSingleOrder = createAction(SET_SINGLE_ORDER);
export const ordersLoading = createAction(ORDERS_LOADING);

export function getOrders(data = {}) {
    return (dispatch) => {
        dispatch(ordersLoading(true));
        axios
            .post(baseUrl.concat('/order/getOrders'), data, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setOrders(res.data.data));
                    dispatch(setPage(data.page || 1));
                }
                dispatch(ordersLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(ordersLoading(false));
            });
    };
}

export function getSingleOrder(id) {
    return (dispatch) => {
        dispatch(ordersLoading(true));
        axios({
            method: 'post',
            url: baseUrl.concat('/order/getOrdersSingle'),
            data: { orderId: id },
            headers: {
                authorization: Cookies.get('token'),
            },
        })
            .then((res) => {
                if (!res.data.error) {
                    const newData = { id, data: res.data.data };
                    dispatch(setSingleOrder(newData));
                }
                dispatch(ordersLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(ordersLoading(false));
            });
    };
}

export function approveOrder(data) {
    return (dispatch) => {
        dispatch(ordersLoading(true));
        axios({
            method: 'post',
            url: baseUrl.concat('/order/approveOrder'),
            data,
            headers: {
                authorization: Cookies.get('token'),
            },
        })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(getSingleOrder(data.order_id));
                }
                dispatch(ordersLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(ordersLoading(false));
            });
    };
}

/** Reducer **/
export const ordersTableData = createReducer([], (state, { value }) => ({
    [SET_ORDERS]: () => {
        const orders = value.orders.map((item) => {
            return { ...item, key: item.id };
        });
        return { count: value.count, orders };
    },
    [SET_PAGE]: () => ({ ...state, currentPage: value }),
    [SET_SINGLE_ORDER]: () => {
        return { singleOrderData: value.data };
    },
    [ORDERS_LOADING]: () => ({ ...state, isLoading: value }),
}));
