import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { SIDEBAR_NAMES } from '../../../constants/names';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import moment from 'moment';
import CollectionCreateForm from './CreateNewModal';
import EditModal from './EditModal';
import { SIDEBAR_IDS } from '../../../constants/ids';
// import { updateCompany, setCompany } from '../../../redux/ducks/TableDataDucks/CompaniesDataDucks';

function CompaniesTable({ tabId, tableContentData, updateCompany, changeCompanyStatus, setCompany, match, ...props }) {
    useEffect(() => {
        const pathName = match.url.split('/')[1].toUpperCase();
        TABLE_CONTENT_CRUDS[SIDEBAR_IDS[pathName]].get();
    }, [tabId]);
    const [newModalVisible, setNewModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editData, setEditData] = useState({});
    const tableColumns = [
        {
            title: 'Name Arm',
            dataIndex: 'name_am',
            key: '0',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Icon',
            dataIndex: 'icon',
            key: '1',
        },
        {
            title: 'Stock ID',
            dataIndex: 'stock_id',
            key: '2',
        },
        {
            title: 'Show',
            dataIndex: 'is_home',
            key: '3',
            sorter: (a, b) => a.is_active - b.is_active,
            render: (text, row) => <Checkbox checked={row.is_active} disabled />,
        },
        // {
        //     title: 'Edit',
        //     dataIndex: 'edit',
        //     key: '4',
        //     render: (text, row) => (
        //         <Button type="primary" onClick={() => editModal(row)}>
        //             Edit
        //         </Button>
        //     ),
        // },
    ];

    const data = [];

    const { isLoading, companies } = tableContentData;

    const onCreate = (values) => {
        // setCategory(values);
        setNewModalVisible(false);
    };

    const editCategory = (values) => {
        // updateCategory(values);
        setEditModalVisible(false);
    };

    const onCancel = () => {
        setNewModalVisible(false);
    };

    const onCancelEdit = () => {
        setEditData({});
        setEditModalVisible(false);
    };

    const editModal = (row) => {
        setEditData(row);
        setEditModalVisible(true);
    };

    companies.length > 0 &&
        companies.forEach((item) => {
            data.push({ ...item, key: item.id });
        });
    return (
        <div>
            {/*<Button onClick={() => setNewModalVisible(true)} type="primary" style={{ marginBottom: 16 }}>*/}
            {/*    Create New*/}
            {/*</Button>*/}

            <CollectionCreateForm visible={newModalVisible} onCreate={onCreate} onCancel={onCancel} />

            {/*<ViewModal*/}
            {/*    visible={editModalVisible}*/}
            {/*    updateCategory={editCategory}*/}
            {/*    onCancelEdit={onCancelEdit}*/}
            {/*    editData={editData}*/}
            {/*/>*/}

            <Table
                pagination={{
                    hideOnSinglePage: true,
                }}
                hideOnSinglePage
                columns={tableColumns}
                dataSource={data.length > 0 ? data : []}
                bordered
                loading={isLoading}
                indentSize={15}
                rowKey={(record) => record.id}
                title={() => SIDEBAR_NAMES[tabId]}
            />
        </div>
    );
}

const mapStateToProps = ({ companiesTableData }) => ({
    tableContentData: companiesTableData,
});

const mapDispatchToProps = {
    // updateCompany,
    // setCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesTable);
