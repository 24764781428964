import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { connect } from 'react-redux';
import { updateGlobalSettings } from '../../redux/ducks/TableDataDucks/GlobalSettingsDucks';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function SingleGS({ data, updateGlobalSettings }) {
    const handleChange = ({ target: { id, value } }) => {
        if (data.type === 'JSON') {
            updateGlobalSettings({ id, value, config: data.config });
        } else {
            updateGlobalSettings({ id, value });
        }
    };

    return (
        <div>
            {data.html ? (
                <div className="unipack-global_settings">
                    <div style={{ marginBottom: '15px' }}>{data.description}</div>

                    <Form.Item name={data.id} style={{ maxWidth: 1200, minWidth: 600 }}>
                        <CKEditor
                            editor={ClassicEditor}
                            data={data.value || ''}
                            onChange={(event, editor) => {
                                const editedData = editor.getData();
                                handleChange({ target: { id: data.config, value: editedData } });
                            }}
                        />
                    </Form.Item>
                </div>
            ) : data.type === 'JSON' ? (
                <>
                    <div className="unipack-global_settings">{data.description}</div>
                    {Object.entries(data.value).map((el, i) => {
                        return (
                            <div style={{ marginBottom: '10px' }}>
                                {`${el[0]}: `}
                                <Input
                                    type={typeof el[1]}
                                    style={{ width: '100%' }}
                                    value={el[1]}
                                    id={el[0]}
                                    onChange={handleChange}
                                />
                            </div>
                        );
                    })}
                </>
            ) : (
                <div className="unipack-global_settings">
                    <div style={{ marginBottom: '15px' }}>{data.description}</div>
                    <Input
                        type={data.type}
                        style={{ width: '200px' }}
                        value={data.value}
                        id={data.config}
                        onChange={handleChange}
                    />
                </div>
            )}
        </div>
    );
}

const mapDispatchToProps = {
    updateGlobalSettings,
};

export default connect(null, mapDispatchToProps)(SingleGS);
