import { categoriesTableData } from './ducks/TableDataDucks/CategoriesDataDucks';
import {
    productsTableData,
    productsByIDReducer,
    productsCompanyReducer,
} from './ducks/TableDataDucks/ProductsDataDucks';
import { ordersTableData } from './ducks/TableDataDucks/OrdersDataDucks';
import { authentication } from './ducks/IsAuthenticatingDucks';
import { reviewsTableData } from './ducks/TableDataDucks/ReviewsTableDucks';
import { usersTableData } from './ducks/TableDataDucks/UsersTableDucks';
import { tabIdReducer } from './ducks/TableDataDucks/TableTabIdDucks';
import { bannersTableData } from './ducks/TableDataDucks/BannersDucks';
import { globalSettingsData } from './ducks/TableDataDucks/GlobalSettingsDucks';
import { adminUsersTableData } from './ducks/TableDataDucks/AdminUsersTableDucks';
import { giftsTableData } from './ducks/TableDataDucks/GiftsTableDucks';
import { companiesTableData } from './ducks/TableDataDucks/CompaniesDataDucks';
import { messagesTableData } from './ducks/TableDataDucks/MessagesDucks';
import { partnershipTableData } from './ducks/TableDataDucks/PartnershipDucks';
import { dashboardData } from './ducks/TableDataDucks/DashboardDucks';

export default {
    categoriesTableData,
    productsTableData,
    ordersTableData,
    authentication,
    reviewsTableData,
    usersTableData,
    tabIdReducer,
    productsByIDReducer,
    productsCompanyReducer,
    bannersTableData,
    globalSettingsData,
    adminUsersTableData,
    giftsTableData,
    companiesTableData,
    messagesTableData,
    partnershipTableData,
    dashboardData,
};
