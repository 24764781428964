import { SIDEBAR_IDS } from './ids';

const {
    CATEGORIES,
    GLOBAL_SETTINGS,
    ORDERS,
    PRODUCTS,
    USERS,
    REVIEWS,
    DASHBOARD,
    BANNERS,
    ADMIN_USERS,
    GIFTS,
    COMPANIES,
    MESSAGES,
    PARTNERSHIP,
} = SIDEBAR_IDS;

export const SIDEBAR_NAMES = {
    [DASHBOARD]: 'Dashboard',
    [CATEGORIES]: 'Categories',
    [GLOBAL_SETTINGS]: 'Global Settings',
    [ORDERS]: 'Orders',
    [PRODUCTS]: 'Products',
    [USERS]: 'Users',
    [REVIEWS]: 'Reviews',
    [BANNERS]: 'Banners',
    [ADMIN_USERS]: 'Admin Users',
    [GIFTS]: 'Gifts',
    [COMPANIES]: 'Companies',
    [MESSAGES]: 'Messages',
    [PARTNERSHIP]: 'Partnership',
};
