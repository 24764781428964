import React from 'react';
import { Modal, Form, Input, Checkbox, Select } from 'antd';

function CollectionCreateForm({ visible, onCreate, onCancel, parentIds }) {
    const [form] = Form.useForm();
    return (
        <Modal
            visible={visible}
            title="Create a new collection"
            okText="Create"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        form.resetFields();
                        values.is_active = !!values.is_active; //TODO change to ant design way
                        values.parent_id = values.parent_id || 0; //TODO change to ant design way
                        // values.priority = values.priority || 0; //TODO change to ant design way
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form form={form} layout="horizontal" name="form_in_modal" initialValues={{}}>
                <Form.Item
                    name="title_am"
                    label="Name Arm"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the title of armenian!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="title_ru"
                    label="Name Rus"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the title of russian!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="title_en"
                    label="Name Eng"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the title of english!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item name="is_active" label="Is Active?" valuePropName={'checked'}>
                    <Checkbox checked={false} />
                </Form.Item>

                <Form.Item name="parent_id" label="Parent ID">
                    <Select defaultValue={0} options={parentIds} />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CollectionCreateForm;
