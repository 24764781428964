import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import { imageUrl } from '../../../constants/api';
import { SIDEBAR_NAMES } from '../../../constants/names';
import { Button, Table } from 'antd';
import EditModal from './EditModal';
import CreateNewGift from './CreateNewGift';
import { createGifts, deleteGifts, updateGifts } from '../../../redux/ducks/TableDataDucks/GiftsTableDucks';
import { DeleteOutlined } from '@ant-design/icons';

function GiftsTable({ tabId, tableContentData, updateGifts, deleteGifts, createGifts }) {
    useEffect(() => {
        TABLE_CONTENT_CRUDS[tabId].get();
    }, [tabId]);

    const [newModalVisible, setNewModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editData, setEditData] = useState({});

    const { gifts, count, isLoading } = tableContentData;
    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: '0',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: '1',
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: '2',
            render: (text, row) => <img src={imageUrl + row.image} width="100" />,
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: '3',
            render: (text, row) => (
                <Button type="primary" onClick={() => editModal(row)}>
                    Edit
                </Button>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: '4',
            render: (text, row) => (
                <Button danger onClick={() => deleteGifts({ id: row.id })}>
                    <DeleteOutlined />
                </Button>
            ),
        },
    ];

    const onCreate = (values) => {
        createGifts(values);
        setNewModalVisible(false);
    };

    const onCancelEdit = () => {
        setEditData({});
        setEditModalVisible(false);
    };

    const editModal = (row) => {
        setEditData(row);
        setEditModalVisible(true);
    };

    const editGift = (values) => {
        updateGifts(values);
        setEditModalVisible(false);
    };

    const onCancel = () => {
        setNewModalVisible(false);
    };

    return (
        <div>
            <Button onClick={() => setNewModalVisible(true)} type="primary" style={{ marginBottom: 16 }}>
                Create New
            </Button>

            <CreateNewGift visible={newModalVisible} onCreate={onCreate} onCancel={onCancel} />

            <EditModal
                visible={editModalVisible}
                updateGift={editGift}
                onCancelEdit={onCancelEdit}
                editData={editData}
            />

            <Table
                hideOnSinglePage
                columns={tableColumns}
                dataSource={gifts}
                bordered
                loading={isLoading}
                indentSize={15}
                rowKey={(record) => record.id}
                title={() => SIDEBAR_NAMES[tabId]}
            />
        </div>
    );
}

const mapStateToProps = ({ giftsTableData }) => ({ tableContentData: giftsTableData });

const mapDispatchToProps = {
    updateGifts,
    deleteGifts,
    createGifts,
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftsTable);
