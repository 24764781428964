import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Upload, Button } from 'antd';
import { imageUrl } from '../../../constants/api';
import { UploadOutlined } from '@ant-design/icons';
import { getBase64 } from '../ProductsTable/ProductPicturesTab';

function CreateNewGift({ visible, onCreate, onCancel }) {
    const [form] = Form.useForm();

    const [imgPath, changePath] = useState();

    const onFileChange = async ({ file }) => {
        if (!file.url && !file.preview) {
            const preview = await getBase64(file);
            changePath(preview);
        }
        form.setFieldsValue({ image: file });
    };

    return (
        <Modal
            visible={visible}
            title="Create new gift"
            okText="Create"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        form.resetFields();
                        const fd = new FormData();
                        Object.entries(values).forEach((value) => {
                            fd.append(value[0], value[1]);
                        });
                        onCreate(fd);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form form={form} layout="horizontal" name="form_in_modal">
                <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the title!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="image"
                    label="Image"
                    rules={[
                        {
                            required: true,
                            message: 'Please upload gift!',
                        },
                    ]}
                >
                    <>
                        <img src={imgPath} width="120" style={{ marginRight: '20px' }} />
                        <Upload onChange={onFileChange} beforeUpload={() => false}>
                            <Button>
                                <UploadOutlined /> Upload
                            </Button>
                        </Upload>
                    </>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CreateNewGift;
