export const SIDEBAR_IDS = {
    DASHBOARD: 0,
    CATEGORIES: 1,
    PRODUCTS: 2,
    ORDERS: 3,
    USERS: 4,
    GLOBAL_SETTINGS: 5,
    REVIEWS: 6,
    BANNERS: 7,
    // ADMIN_USERS: 8,
    GIFTS: 9,
    COMPANIES: 10,
    MESSAGES: 11,
    PARTNERSHIP: 12,
};
