import React, { useEffect } from 'react';
import { Input, Form, Modal, Rate, Checkbox } from 'antd';
import moment from 'moment';

function ViewModal({ visible, viewData, onOk, onCancel }) {
    const { first_name, last_name, user_id, rate, comment_text } = viewData;
    return (
        <Modal title={false} visible={visible} onOk={onOk} onCancel={onCancel}>
            <Form name="nest-messages">
                <Form.Item label="First name">{first_name}</Form.Item>
                <Form.Item label="Last name">{last_name}</Form.Item>
                <Form.Item label="User ID">{user_id}</Form.Item>
                <Form.Item label="Rate">
                    <Rate disabled allowHalf defaultValue={rate} />
                </Form.Item>
                {comment_text && <Form.Item label="Comment text">{comment_text}</Form.Item>}
            </Form>
        </Modal>
    );
}

export default ViewModal;
