import { createAction, createReducer } from '../../helpers';
import { baseUrl } from '../../../constants/api';
import axios from 'axios';
import Cookies from 'js-cookie';

/** Action types **/
const SET_CATEGORIES = 'SET_CATEGORIES';
const CATEGORIES_LOADING = 'CATEGORIES_LOADING';

/** Actions **/

export const setCategories = createAction(SET_CATEGORIES);
export const categoriesLoading = createAction(CATEGORIES_LOADING);

export function getCategories() {
    return (dispatch) => {
        dispatch(categoriesLoading(true));
        axios({
            method: 'post',
            url: baseUrl.concat('/category/get'),
        })
            .then((res) => {
                if (!res.data.error && res.data.data.length > 0) {
                    dispatch(setCategories(res.data.data));
                }
                dispatch(categoriesLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(categoriesLoading(false));
            });
    };
}

export function setCategory(data) {
    return (dispatch) => {
        dispatch(categoriesLoading(true));
        axios
            .post(baseUrl + '/category/set', data, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error && res.data.data.length > 0) {
                    dispatch(setCategories(res.data.data));
                }
                dispatch(categoriesLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(categoriesLoading(false));
            });
    };
}

export function updateCategory(data) {
    return (dispatch) => {
        dispatch(categoriesLoading(true));
        axios
            .post(baseUrl + '/category/update', data, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error && res.data.data.length > 0) {
                    dispatch(setCategories(res.data.data));
                }
                dispatch(categoriesLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(categoriesLoading(false));
            });
    };
}

export function changeCategoryStatus(id) {
    return (dispatch) => {
        dispatch(categoriesLoading(true));
        axios
            .post(baseUrl + '/category/changeStatus', id, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error && res.data.data.length > 0) {
                    dispatch(setCategories(res.data.data));
                }
                dispatch(categoriesLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(categoriesLoading(false));
            });
    };
}
/** Reducer **/
export const categoriesTableData = createReducer({ categories: {}, isLoading: true }, (state, { value }) => ({
    [SET_CATEGORIES]: () => {
        return { ...state, categories: value };
    },
    [CATEGORIES_LOADING]: () => ({ ...state, isLoading: value }),
    // [UPDATE_EXAMPLE]: ()=> ({ ...state, ...value }),
    // [RESET_EXAMPLE]: ()=> initial,
}));
