import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import UserInfoTab from './UserInfoTab';
import UserOrdersTab from '../OrdersTable/UserOrdersTab';
import UserWishListTab from './UserWishListTab';
import UserReviewTab from './UserReviewTab';

const { TabPane } = Tabs;

function UserTabs({ data }) {
    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Info" key="1">
                    <UserInfoTab dataInfo={data.info || null} />
                </TabPane>
                <TabPane tab="Orders" key="2">
                    <UserOrdersTab showDataPicker={false} orders={data.orders || []} />
                </TabPane>
                <TabPane tab="Wish List" key="3">
                    <UserWishListTab wishlist={data.wishList || []} />
                </TabPane>
                <TabPane tab="Review" key="4">
                    <UserReviewTab reviews={data.review || []} />
                </TabPane>
            </Tabs>
        </div>
    );
}

export default UserTabs;
