import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Col, Form, Input, Row, Spin } from 'antd';
import { changeUserStatus } from '../../../redux/ducks/TableDataDucks/UsersTableDucks';

function UserInfoTab({ id, dataInfo, changeUserStatus, usersTableData }) {
    const [form] = Form.useForm();
    const { isLoading } = usersTableData;
    const onSave = function () {
        form.validateFields()
            .then((values) => {
                // form.resetFields();
                changeUserStatus({ id: values.id, is_active: values.is_active, bonus: values.bonus });
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };
    return (
        <>
            {isLoading ? (
                <Spin />
            ) : (
                <div>
                    {!!dataInfo && (
                        <Form form={form} name="order_info" initialValues={dataInfo}>
                            <Form.Item label="ID:" name="id">
                                {dataInfo.id}
                            </Form.Item>

                            <Form.Item label="Email:" name="email">
                                {dataInfo.email}
                            </Form.Item>

                            <Form.Item label="Is Active:" name="is_active" valuePropName={'checked'}>
                                <Checkbox />
                            </Form.Item>

                            {dataInfo.type === 1 && (
                                <>
                                    <Form.Item label="First name:" name="first_name">
                                        {dataInfo.first_name}
                                    </Form.Item>

                                    <Form.Item label="Last name:" name="last_name">
                                        {dataInfo.last_name}
                                    </Form.Item>
                                </>
                            )}

                            {dataInfo.type === 2 && (
                                <>
                                    <Form.Item label="Tax ID:" name="tax_id_number">
                                        {dataInfo.tax_id_number}
                                    </Form.Item>

                                    <Form.Item label="Company name:" name="company_name">
                                        {dataInfo.company_name}
                                    </Form.Item>

                                    <Form.Item label="Stock ID:" name="stock_id">
                                        {dataInfo.stock_id}
                                    </Form.Item>
                                </>
                            )}

                            <Form.Item label="Phone:" name="phone">
                                {dataInfo.phone}
                            </Form.Item>

                            <Form.Item label="Bonus:" name="bonus">
                                <Input style={{ width: '200px' }} />
                            </Form.Item>

                            <Button
                                onClick={onSave}
                                style={{ left: '10px', marginBottom: '50px' }}
                                size="large"
                                type="primary"
                            >
                                Save
                            </Button>

                            {!!dataInfo.shipping_details && (
                                <div className="order__info__unipack--container" style={{ marginBottom: '20px' }}>
                                    <Row gutter={[24, 8]}>
                                        <Col className="order__info__unipack--card" span={11}>
                                            <div style={{ fontWeight: 700, textAlign: 'center', fontSize: '18px' }}>
                                                Shipping Details:
                                            </div>
                                            <div className="order__info__unipack--card-header">
                                                <span>Country:</span>
                                                <span>{JSON.parse(dataInfo.shipping_details).country}</span>
                                            </div>
                                            <div className="order__info__unipack--card-header">
                                                <span>Street address:</span>
                                                <span>{JSON.parse(dataInfo.shipping_details).streetAddress}</span>
                                            </div>
                                            <div className="order__info__unipack--card-header">
                                                <span>Apt:</span>
                                                <span>{JSON.parse(dataInfo.shipping_details).apt}</span>
                                            </div>
                                            <div className="order__info__unipack--card-header">
                                                <span>City:</span>
                                                <span>{JSON.parse(dataInfo.shipping_details).city}</span>
                                            </div>
                                            <div className="order__info__unipack--card-header">
                                                <span>Post code:</span>
                                                <span>{JSON.parse(dataInfo.shipping_details).postCode}</span>
                                            </div>
                                            <div className="order__info__unipack--card-header">
                                                <span>First name:</span>
                                                <span>{JSON.parse(dataInfo.shipping_details).first_name}</span>
                                            </div>
                                            <div className="order__info__unipack--card-header">
                                                <span>Last name:</span>
                                                <span>{JSON.parse(dataInfo.shipping_details).last_name}</span>
                                            </div>
                                            <div className="order__info__unipack--card-header">
                                                <span>Phone:</span>
                                                <span>{JSON.parse(dataInfo.shipping_details).phone}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Form>
                    )}
                </div>
            )}
        </>
    );
}

const mapStateToProps = ({ usersTableData }) => ({
    usersTableData,
});

const mapDispatchToProps = {
    changeUserStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoTab);
