import { createAction, createReducer } from '../helpers';
import axios from 'axios';
import { baseUrl } from '../../constants/api';
import Cookies from 'js-cookie';
import { setIsAuthenticated, setIsLoading } from './IsAuthenticatingDucks';
import history from '../../helpers/history';

const SET_USER = 'SET_USER';

function setUser(user, updateRoute = false) {
    return (dispatch) => {
        Cookies.set('token', user.token);
        dispatch({ type: SET_USER, value: user });
        dispatch(setIsAuthenticated(true));
        dispatch(setIsLoading(false));
        // if (updateRoute) {
        //     console.log('push')
        //     // history.replace('/dashboard');
        //     // window.location.reload();
        // }
    };
}

function removeUser() {
    return (dispatch) => {
        Cookies.remove('token');
        dispatch({ type: SET_USER, value: {} });
        dispatch(setIsAuthenticated(false));
        dispatch(setIsLoading(false));
    };
}

export function getUser() {
    //prettier-ignore
    return (dispatch) => {
        axios.post(baseUrl + '/users/getAdminAccount', {}, {
            headers: {
                authorization: Cookies.get('token'),
            }
        }).then((res) => {
            const { data, error } = res.data;
            if (error) {
                dispatch(removeUser());
            } else {
                dispatch(setUser(data));
            }
        }).catch((err) => {
            console.log(err);
            dispatch(setIsLoading(false));
        });
    };
}

export function signIn(data) {
    return (dispatch) => {
        axios.post(baseUrl + '/users/signInAdmin', data).then((res) => {
            const { data, error } = res.data;
            if (error) {
                console.log('error:', error);
            } else {
                dispatch(setUser(data, true));
            }
        });
    };
}

// export function signUp(data) {
//     return (dispatch) => {
//         axios.post(baseUrl + 'users/signUp', data).then((res) => {
//             const { data, error } = res.data;
//             if (error) {
//             } else {
//                 dispatch(setUser(data));
//             }
//         });
//     };
// }

export function signOut() {
    return (dispatch) => {
        axios
            .post(
                baseUrl + '/users/signOutAdmin',
                {},
                {
                    headers: {
                        authorization: Cookies.get('token'),
                    },
                }
            )
            .then((res) => {
                const { error } = res.data;
                if (!error) {
                    Cookies.remove('token');
                    dispatch(setIsAuthenticated(false));
                } else {
                    console.log(error);
                }
            });
    };
}

export const user = createReducer({}, (state, { value }) => ({
    [SET_USER]: () => value,
}));
