import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Form, Select, Checkbox, Button, Input, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { approveOrder } from '../../../redux/ducks/TableDataDucks/OrdersDataDucks';

const orderStatus = ['Pending', 'Rejected', 'Approved'];
const orderType = ['', 'CASH', 'BA'];
const { Option } = Select;
const { TextArea } = Input;

function OrderInfoTab({ id, data, approveOrder, ordersTableData }) {
    const [form] = Form.useForm();
    const { isLoading } = ordersTableData;
    const onSave = function () {
        form.validateFields()
            .then((values) => {
                form.resetFields();
                approveOrder({ order_id: values.order_id, order_status: values.order_status, notes: values.adm_note });
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };
    console.log(ordersTableData);
    return (
        <>
            {isLoading ? (
                <Spin />
            ) : (
                !!data && (
                    <div className="order__info__unipack--container">
                        <div>
                            <Form form={form} name="order_info" initialValues={data}>
                                <Form.Item hidden label="Order ID:" name="order_id">
                                    {data.order_id}
                                </Form.Item>

                                <Form.Item label="Order Status:" name="order_status">
                                    {orderStatus[data.order_status]}
                                </Form.Item>
                                <Form.Item label="Stock ID:" name="stock_id">
                                    {data.stock_id}
                                </Form.Item>
                                <Form.Item label="Created at:" name="created_at">
                                    {moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')}
                                </Form.Item>
                                <Form.Item label="Updated at:" name="updated_at">
                                    {data.updated_at && moment(data.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                                </Form.Item>
                                <Form.Item label="Order type:" name="order_type">
                                    {orderType[data.order_type]}
                                </Form.Item>
                                <Form.Item label="User ID:" name="user_id">
                                    <Link to={'/users/' + data.user_id}>{data.user_id}</Link>
                                </Form.Item>
                                {!!data.user_note && (
                                    <Form.Item label="User note:" name="user_note">
                                        {data.user_note}
                                    </Form.Item>
                                )}
                            </Form>
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <Row gutter={[24, 8]}>
                                <Col className="order__info__unipack--card" span={11}>
                                    <div style={{ fontWeight: 700, textAlign: 'center', fontSize: '18px' }}>
                                        Amount:
                                    </div>
                                    <div
                                        style={{ fontWeight: 700, textAlign: 'center', fontSize: '16px' }}
                                        className="order__info__unipack--card-header"
                                    >
                                        <span>Subtotal:</span>
                                        <span>{data.sub_total}</span>
                                    </div>
                                    <div
                                        style={{ fontWeight: 700, textAlign: 'center', fontSize: '16px' }}
                                        className="order__info__unipack--card-header"
                                    >
                                        <span>Shipping price:</span>
                                        <span>{data.shipping_price}</span>
                                    </div>
                                    <div
                                        style={{ fontWeight: 700, textAlign: 'center', fontSize: '16px' }}
                                        className="order__info__unipack--card-header"
                                    >
                                        <span>Bonus:</span>
                                        <span>{data.bonus}</span>
                                    </div>
                                    <div
                                        style={{ fontWeight: 700, textAlign: 'center', fontSize: '16px' }}
                                        className="order__info__unipack--card-header"
                                    >
                                        <span>Total amount:</span>
                                        <span>{data.total_amount}</span>
                                    </div>
                                </Col>
                                <Col className="order__info__unipack--card" span={11}>
                                    <div style={{ fontWeight: 700, textAlign: 'center', fontSize: '18px' }}>
                                        Shipping Details:
                                    </div>
                                    <div className="order__info__unipack--card-header">
                                        <span>Country:</span>
                                        <span>{JSON.parse(data.shipping_details).country}</span>
                                    </div>
                                    <div className="order__info__unipack--card-header">
                                        <span>Street address:</span>
                                        <span>{JSON.parse(data.shipping_details).streetAddress}</span>
                                    </div>
                                    <div className="order__info__unipack--card-header">
                                        <span>Apt:</span>
                                        <span>{JSON.parse(data.shipping_details).apt}</span>
                                    </div>
                                    <div className="order__info__unipack--card-header">
                                        <span>City:</span>
                                        <span>{JSON.parse(data.shipping_details).city}</span>
                                    </div>
                                    <div className="order__info__unipack--card-header">
                                        <span>Post code:</span>
                                        <span>{JSON.parse(data.shipping_details).postCode}</span>
                                    </div>
                                    <div className="order__info__unipack--card-header">
                                        <span>First name:</span>
                                        <span>{JSON.parse(data.shipping_details).first_name}</span>
                                    </div>
                                    <div className="order__info__unipack--card-header">
                                        <span>Last name:</span>
                                        <span>{JSON.parse(data.shipping_details).last_name}</span>
                                    </div>
                                    <div className="order__info__unipack--card-header">
                                        <span>Phone:</span>
                                        <span>{JSON.parse(data.shipping_details).phone}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )
            )}
        </>
    );
}

const mapStateToProps = ({ ordersTableData }) => ({
    ordersTableData,
});

const mapStateToDispatch = {
    approveOrder,
};

export default connect(mapStateToProps, mapStateToDispatch)(OrderInfoTab);
