import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SIDEBAR_NAMES } from '../../../constants/names';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import { Checkbox, Table } from 'antd';
import { changeUserStatus } from '../../../redux/ducks/TableDataDucks/UsersTableDucks';
import { Link } from 'react-router-dom';
import { SIDEBAR_IDS } from '../../../constants/ids';

UsersTable.propTypes = {
    tabId: PropTypes.number.isRequired,
};

function UsersTable({ tabId, tableContentData, changeUserStatus, match }) {
    useEffect(() => {
        const pathName = match.url.split('/')[1].toUpperCase();
        TABLE_CONTENT_CRUDS[SIDEBAR_IDS[pathName]].get();
    }, [tabId]);

    const { users, count, isLoading, currentPage } = tableContentData;
    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: '0',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
            render: (text, row) => (
                <span style={{ marginRight: 16 }}>
                    <Link
                        to={{
                            pathname: '/users/' + row.id,
                            state: { tabId: tabId },
                        }}
                    >
                        {row.id}
                    </Link>
                </span>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: '1',
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: '2',
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: '3',
        },
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            key: '4',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: '5',
        },
        {
            title: 'Is Active',
            dataIndex: 'is_active',
            key: '6',
            render: (text, row) => (
                <Checkbox
                    checked={row.is_active}
                    onChange={() => changeUserStatus({ id: row.id, is_active: !row.is_active })}
                />
            ),
        },
    ];
    return (
        <div>
            <Table
                pagination={{
                    onChange: (page) => TABLE_CONTENT_CRUDS[tabId].get({ page }),
                    position: ['none', 'bottomRight'],
                    hideOnSinglePage: true,
                    pageSize: 20,
                    total: count,
                    current: currentPage,
                }}
                hideOnSinglePage
                columns={tableColumns}
                dataSource={users || []}
                bordered
                loading={isLoading}
                indentSize={15}
                rowKey={(record) => record.id}
                title={() => SIDEBAR_NAMES[tabId]}
            />
        </div>
    );
}

const mapStateToProps = ({ usersTableData }) => ({ tableContentData: usersTableData });

const mapDispatchToProps = {
    changeUserStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
