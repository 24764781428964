import React, { useState } from 'react';
import { Menu, Layout } from 'antd';
import MenuItem from './MenuItem';
import { SIDEBAR_IDS } from '../../constants/ids';
import { SIDEBAR_NAMES } from '../../constants/names';

const { Sider } = Layout;
const sidebarIdList = Object.values(SIDEBAR_IDS);

function LeftSidebar() {
    return (
        <>
            <Sider
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                }}
            >
                <Menu style={{ width: 200 }} defaultSelectedKeys={[0]} mode="inline" theme="dark">
                    {sidebarIdList.map((id) => (
                        <MenuItem key={id} id={id} icon={id} name={SIDEBAR_NAMES[id]} />
                    ))}
                </Menu>
            </Sider>
        </>
    );
}

export default LeftSidebar;
