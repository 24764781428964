import React, { useEffect } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signIn } from '../redux/ducks/UserDucks';

function Login({ signIn, isAuthenticated }) {
    const history = useHistory();

    const onFinish = (values) => {
        const { username, password } = values;
        signIn({ username, password });
        // history.push('/dashboard');
    };

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/dashboard');
        }
    }, [history, isAuthenticated]);

    return (
        <div className="login-unpack">
            <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={onFinish}>
                <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                {/*<Form.Item>*/}
                {/*    <Form.Item name="remember" valuePropName="checked" noStyle>*/}
                {/*        <Checkbox>Remember me</Checkbox>*/}
                {/*    </Form.Item>*/}

                {/*    <a className="login-form-forgot" href="#">*/}
                {/*        Forgot password*/}
                {/*    </a>*/}
                {/*</Form.Item>*/}

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Log in
                    </Button>
                    {/*Or <a href="#">register now!</a>*/}
                </Form.Item>
            </Form>
        </div>
    );
}

const mapStateToProps = ({ authentication: { isAuthenticated } }) => ({
    isAuthenticated,
});

const mapDispatchToProps = {
    signIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
