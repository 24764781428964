import React, { useEffect } from 'react';
import './App.css';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import Login from './Auth/Login';
import MainLayout from './components/MainLayout';
import { useHistory } from 'react-router-dom';
import { getUser } from './redux/ducks/UserDucks';

function App({ authentication: { isAuthenticated, isLoading }, getUser, match }) {
    const history = useHistory();

    useEffect(() => {
        getUser();
    }, [getUser]);

    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            history.push('/login');
        } else if (isAuthenticated && !isLoading) {
            history.push('/dashboard');
        }
    }, [history, isAuthenticated, isLoading]);

    return (
        <div>
            {
                <Switch>
                    {isAuthenticated && <Route path="/" component={MainLayout} />}
                    <Route path="/login" component={Login} />
                </Switch>
            }
        </div>
    );
}

const mapStateToProps = ({ authentication }) => ({ authentication });

const mapDispatchToProps = {
    getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
