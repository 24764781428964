import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { SIDEBAR_NAMES } from '../../../constants/names';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import { Checkbox, Table } from 'antd';
import { updateAdminUsers } from '../../../redux/ducks/TableDataDucks/AdminUsersTableDucks';
import moment from 'moment';

function AdminUsers({ tabId, tableContentData, updateAdminUsers }) {
    useEffect(() => {
        TABLE_CONTENT_CRUDS[tabId].get();
    }, [tabId]);
    const { adminUsers, count, isLoading } = tableContentData;
    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: '0',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
            render: (text, row) => <span style={{ marginRight: 16 }}>{row.id}</span>,
        },
        {
            title: 'User Name',
            dataIndex: 'username',
            key: '1',
        },
        {
            title: 'Is Active',
            dataIndex: 'is_active',
            key: '2',
            render: (text, row) => (
                <Checkbox checked={row.is_active} onChange={() => updateAdminUsers({ id: row.id })} />
            ),
        },
        {
            title: 'Create at',
            dataIndex: 'created_at',
            key: '3',
            render: (text, row) => <span>{moment(row.created_at).format('YYYY-MM-DD')}</span>,
        },
    ];
    return (
        <div>
            <Table
                pagination={false}
                hideOnSinglePage
                columns={tableColumns}
                dataSource={adminUsers || []}
                bordered
                loading={isLoading}
                indentSize={20}
                rowKey={(record) => record.id}
                title={() => SIDEBAR_NAMES[tabId]}
            />
        </div>
    );
}

const mapStateToProps = ({ adminUsersTableData }) => ({ tableContentData: adminUsersTableData });

const mapDispatchToProps = { updateAdminUsers };

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
