import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Checkbox, Tabs, Row, Col, Select, message, Upload } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';

import { deleteBanner, getBanners, setBanner, updateBanner } from '../../../redux/ducks/TableDataDucks/BannersDucks';
import { baseUrl, imageUrl } from '../../../constants/api';
import BannerEditModal from './BannerEditModal';
import CreateNewBanner from './CreateNewBanner';
import { SIDEBAR_IDS } from '../../../constants/ids';

Banners.propTypes = {
    tabId: PropTypes.number.isRequired,
};
const { TabPane } = Tabs;
const { Option } = Select;

function Banners({ tabId, banners, isLoading, updateBanner, setBanner, deleteBanner, match }) {
    const [newModalVisible, setNewModalVisible] = useState(false);
    const [selectedPosition, changePosition] = useState(false);
    const [selectedTab, changeTab] = useState('');
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editBannerData, setEditBannerData] = useState({});
    const type = match.url.split('/')[2];
    useEffect(() => {
        const pathName = match.url.split('/')[1].toUpperCase();
        TABLE_CONTENT_CRUDS[SIDEBAR_IDS[pathName]].get(type);
    }, [tabId]);

    useEffect(() => {
        const pages = Object.keys(banners);
        if (pages.length > 0) {
            changeTab(pages[0]);
            if (!selectedPosition) {
                const position = Object.keys(banners[pages[0]])[0];
                changePosition(position);
            }
        }
    }, [banners]);

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: '0',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: '1',
            render: (text, row) => <Checkbox checked={row.active} />,
        },
        {
            title: 'Language',
            dataIndex: 'language',
            key: '2',
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: '3',
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: '4',
            sorter: (a, b) => a.priority - b.priority,
        },
        {
            title: 'Page',
            dataIndex: 'page',
            key: '5',
        },
        {
            title: 'Path',
            dataIndex: 'path',
            key: '6',
            render: (text, row) => <img src={imageUrl + row.path} width="50" />,
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: '7',
            render: (text, row) => (
                <Button type="primary" onClick={() => editBannerHandle(row)}>
                    Edit
                </Button>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: '8',
            render: (text, row) => (
                <Button danger onClick={() => deleteBanner({ id: row.id, type })}>
                    <DeleteOutlined />
                </Button>
            ),
        },
    ];

    let data = [];

    if (!!selectedPosition && !!selectedTab) {
        data = [];
        Object.values(banners[selectedTab][selectedPosition]).forEach((rows) => {
            rows.forEach((row) => {
                data.push({ ...row, key: row.id });
            });
        });
    }
    const names = Object.keys(banners);

    const createTab = (tab) => {
        const options = Object.keys(banners[tab]);
        return (
            <Select key={tab} size={'large'} defaultValue={options[0]} onChange={onPositionChange}>
                {options.map((name) => (
                    <Option key={name} value={name}>
                        {name}
                    </Option>
                ))}
            </Select>
        );
    };

    const onCreate = (values) => {
        setBanner({ values, type });
        setNewModalVisible(false);
    };

    const onCancel = () => {
        setNewModalVisible(false);
    };

    const onTabChange = (tab) => {
        const position = Object.keys(banners[tab])[0];
        changePosition(position);
        changeTab(tab);
    };

    const editBanner = (values) => {
        updateBanner(values);
        setEditModalVisible(false);
    };

    const onCancelEdit = () => {
        setEditBannerData({});
        setEditModalVisible(false);
    };

    const editBannerHandle = (row) => {
        setEditBannerData(row);
        setEditModalVisible(true);
    };

    const onPositionChange = (position) => {
        changePosition(position);
    };

    const renderTabs = (tabs) => {
        return tabs.map((tab) => {
            return (
                <TabPane tab={tab} key={tab}>
                    <Col span={6}>
                        Position: <br />
                        {createTab(tab)}
                    </Col>
                </TabPane>
            );
        });
    };
    return (
        <div style={{ maxWidth: '98%' }}>
            <Row gutter={[48, 48]}>
                <Col>
                    <Tabs
                        size="large"
                        tabBarStyle={{
                            marginBottom: 24,
                        }}
                        onChange={onTabChange}
                    >
                        {renderTabs(names)}
                    </Tabs>
                </Col>
            </Row>

            <Button onClick={() => setNewModalVisible(true)} type="primary" style={{ marginBottom: 16 }}>
                Create New
            </Button>

            <CreateNewBanner type={type} visible={newModalVisible} onCreate={onCreate} onCancel={onCancel} />

            <BannerEditModal
                visible={editModalVisible}
                updateBanner={editBanner}
                onCancelEdit={onCancelEdit}
                editData={editBannerData}
            />

            <Table
                hideOnSinglePage
                columns={tableColumns}
                loading={isLoading}
                dataSource={data.length > 0 ? data : null}
                bordered
                indentSize={15}
                rowKey={(record) => record.id}
            />
        </div>
    );
}

const mapStateToProps = ({ bannersTableData }) => ({
    banners: bannersTableData.banners,
    isLoading: bannersTableData.isLoading,
});

const mapDispatchToProps = {
    getBanners,
    updateBanner,
    setBanner,
    deleteBanner,
};

export default connect(mapStateToProps, mapDispatchToProps)(Banners);
