import { createAction, createReducer } from '../../helpers';
import { baseUrl } from '../../../constants/api';
import axios from 'axios';
import Cookies from 'js-cookie';

/** Action types **/
const SET_DASHBOARD = 'SET_DASHBOARD';
const DASHBOARD_LOADING = 'DASHBOARD_LOADING';

/** Actions **/

export const setDashboard = createAction(SET_DASHBOARD);
export const dashboardLoading = createAction(DASHBOARD_LOADING);

export function getDashboard(data) {
    return (dispatch) => {
        dispatch(dashboardLoading(true));
        axios({
            method: 'post',
            data,
            url: baseUrl.concat('/dashboard/get'),
            headers: {
                authorization: Cookies.get('token'),
            },
        })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setDashboard(res.data.data));
                }
                dispatch(dashboardLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(dashboardLoading(false));
            });
    };
}

/** Reducer **/
export const dashboardData = createReducer(
    { dashboard: { orders: {}, products: {}, product_reviews: {}, users: {} }, isLoading: true, notify: false },
    (state, { value }) => ({
        [SET_DASHBOARD]: () => {
            const keys = ['orders', 'products', 'product_reviews', 'users'];
            const periods = ['week', 'month'];
            let notify = false;
            keys.forEach((key) => {
                periods.forEach((period) => {
                    if (state.dashboard[key][period] !== value[key][period]) {
                        notify = true;
                    }
                });
            });
            return { ...state, dashboard: value, notify };
        },
        [DASHBOARD_LOADING]: () => ({ ...state, isLoading: value }),
        // [UPDATE_EXAMPLE]: ()=> ({ ...state, ...value }),
        // [RESET_EXAMPLE]: ()=> initial,
    })
);
