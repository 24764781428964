import React, { useState } from 'react';
import { Button, Form, Select, Table, Tooltip, DatePicker } from 'antd';
import moment from 'moment';
import ShippingDetailsModal from './ShippingDetailsModal';
import { SearchOutlined } from '@ant-design/icons';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import { SIDEBAR_NAMES } from '../../../constants/names';
import { Link } from 'react-router-dom';
const { RangePicker } = DatePicker;
const orderStatus = ['Pending', 'Rejected', 'Approved', 'all'];
const orderType = ['', 'CASH', 'BA'];
const initialDate = moment(new Date()).format('YYYY-MM-DD');
const dateFormat = 'YYYY/MM/DD';

function UserOrdersTab({ tabId, orders, count, currentPage, isLoading, showDataPicker }) {
    const [shippingDetailsModalVisible, setShippingDetailsModalVisible] = useState(false);
    const [SDData, setSDData] = useState({});
    const [filterData, setFilterData] = useState({ from: initialDate, to: initialDate, type: 'all' });
    const onCloseModal = () => {
        setShippingDetailsModalVisible(false);
    };
    const selectType = [
        { value: 0, label: orderStatus[0] },
        { value: 1, label: orderStatus[1] },
        { value: 2, label: orderStatus[2] },
        { value: 'all', label: orderStatus[3] },
    ];
    const openModal = (row) => {
        const detailsData = JSON.parse(row.shipping_details);
        setSDData(detailsData);
        setShippingDetailsModalVisible(true);
    };
    const onDateChange = (dates, [from, to]) => {
        from = new Date(from);
        to = new Date(to);
        setFilterData({ ...filterData, from, to });
    };
    const onSelectChange = (value) => {
        setFilterData({ ...filterData, type: value });
    };

    const searchOnClick = () => {
        TABLE_CONTENT_CRUDS[tabId].get(filterData);
    };

    const tableColumns = [
        {
            title: 'Order ID',
            dataIndex: 'order_id',
            key: '0',
            render: (text, row) => (
                <span style={{ marginRight: 16 }}>
                    <Link to={'/orders/' + row.order_id}>{row.order_id}</Link>
                </span>
            ),
        },
        {
            title: 'Order Status',
            dataIndex: 'order_status',
            key: '1',
            render: (text, row) => orderStatus[row.order_status],
        },
        {
            title: 'Order Type',
            dataIndex: 'order_type',
            key: '3',
            render: (text, row) => orderType[row.order_type],
        },
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: '4',
            render: (text, row) => (
                <span style={{ marginRight: 16 }}>
                    <Link to={'/users/' + row.user_id}>{row.user_id}</Link>
                </span>
            ),
        },
        {
            title: 'Stock ID',
            dataIndex: 'stock_id',
            key: '5',
        },
        {
            title: 'Total Amount',
            dataIndex: 'total_amount',
            key: '6',
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: '14',
            render: (text, row) => <span>{moment(row.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>,
        },
    ];

    return (
        <div>
            <ShippingDetailsModal visible={shippingDetailsModalVisible} onCloseModal={onCloseModal} SDData={SDData} />
            {showDataPicker && (
                <div className="orders__search__unipack">
                    <RangePicker
                        defaultValue={[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]}
                        format={dateFormat}
                        onCalendarChange={onDateChange}
                    />
                    <Select
                        onChange={onSelectChange}
                        defaultValue={'all'}
                        options={selectType}
                        className="orders__search__select__unipack"
                    />
                    <Button onClick={searchOnClick} type="primary" icon={<SearchOutlined />}>
                        Search
                    </Button>
                </div>
            )}
            <Table
                pagination={{
                    onChange: (page) => TABLE_CONTENT_CRUDS[tabId].get({ ...filterData, page }),
                    position: ['none', 'bottomRight'],
                    hideOnSinglePage: true,
                    pageSize: 20,
                    total: count,
                    current: currentPage,
                }}
                hideOnSinglePage
                columns={tableColumns}
                dataSource={orders}
                bordered
                loading={isLoading}
                indentSize={15}
                rowKey={(record) => record.id}
                title={() => SIDEBAR_NAMES[tabId]}
            />
        </div>
    );
}

export default UserOrdersTab;
