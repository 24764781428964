import React, { useEffect } from 'react';
import { Input, Form, Modal, Rate, Checkbox } from 'antd';
import moment from 'moment';
const { TextArea } = Input;

function ViewModal({ visible, viewData, onOk, onCancel }) {
    const { id, user_id, product_id, rate, comment_text, is_active, created_at } = viewData;
    return (
        <Modal title={false} visible={visible} onOk={onOk} onCancel={onCancel}>
            <Form name="nest-messages">
                <Form.Item label="ID">{id}</Form.Item>
                <Form.Item label="User ID">{user_id}</Form.Item>
                <Form.Item label="Product ID">{product_id}</Form.Item>
                <Form.Item label="Rate">
                    <Rate disabled allowHalf defaultValue={rate} />
                </Form.Item>
                {comment_text && <Form.Item label="Comment text">{comment_text}</Form.Item>}
                <Form.Item label="Is Active">
                    {' '}
                    <Checkbox checked={is_active} />
                </Form.Item>
                <Form.Item label="Create at">{moment(created_at).format('YYYY-MM-DD HH:mm')}</Form.Item>
            </Form>
        </Modal>
    );
}

export default ViewModal;
