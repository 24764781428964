import { SIDEBAR_IDS } from './ids';
import { getCategories } from '../redux/ducks/TableDataDucks/CategoriesDataDucks';
import { getProducts, getSingleProduct, searchProduct } from '../redux/ducks/TableDataDucks/ProductsDataDucks';
import { getOrders, getSingleOrder } from '../redux/ducks/TableDataDucks/OrdersDataDucks';
import { deleteReviews, getReviews, updateReviewStatus } from '../redux/ducks/TableDataDucks/ReviewsTableDucks';
import { getUsers, getSingleUser } from '../redux/ducks/TableDataDucks/UsersTableDucks';
import { getBanners } from '../redux/ducks/TableDataDucks/BannersDucks';
import { getGlobalSettings } from '../redux/ducks/TableDataDucks/GlobalSettingsDucks';
import { getAdminUsers } from '../redux/ducks/TableDataDucks/AdminUsersTableDucks';
import { getGifts } from '../redux/ducks/TableDataDucks/GiftsTableDucks';
import { getCompanies } from '../redux/ducks/TableDataDucks/CompaniesDataDucks';
import { getDashboard } from '../redux/ducks/TableDataDucks/DashboardDucks';
import { deleteMessage, getMessages } from '../redux/ducks/TableDataDucks/MessagesDucks';
import { getPartnership } from '../redux/ducks/TableDataDucks/PartnershipDucks';
import store from '../redux/store';

const {
    CATEGORIES,
    GLOBAL_SETTINGS,
    ORDERS,
    PRODUCTS,
    USERS,
    REVIEWS,
    DASHBOARD,
    BANNERS,
    // ADMIN_USERS,
    GIFTS,
    COMPANIES,
    PARTNERSHIP,
    MESSAGES,
} = SIDEBAR_IDS;

export const TABLE_CONTENT_CRUDS = {
    [DASHBOARD]: {
        get: (year) => store.dispatch(getDashboard(year)),
    },
    [CATEGORIES]: { get: () => store.dispatch(getCategories()) },
    [PRODUCTS]: {
        get: (data) => store.dispatch(getProducts(data)),
        getSingle: (id) => store.dispatch(getSingleProduct(id)),
        searchProduct: (text) => store.dispatch(searchProduct({ findWord: text })),
    },
    [ORDERS]: {
        get: (data) => store.dispatch(getOrders(data)),
        getSingleOrder: (id) => store.dispatch(getSingleOrder(id)),
    },
    [USERS]: {
        get: (data) => store.dispatch(getUsers(data)),
        getSingleUser: (id) => store.dispatch(getSingleUser(id)),
    },
    [GLOBAL_SETTINGS]: { get: () => store.dispatch(getGlobalSettings()) },
    [REVIEWS]: {
        get: (data) => store.dispatch(getReviews(data)),
        delete: (id) => store.dispatch(deleteReviews(id)),
        update: (id) => store.dispatch(updateReviewStatus(id)),
    },
    [BANNERS]: {
        get: (type) => store.dispatch(getBanners(type)),
    },
    // [ADMIN_USERS]: {
    //     get: () => store.dispatch(getAdminUsers()),
    // },
    [GIFTS]: {
        get: () => store.dispatch(getGifts()),
    },
    [COMPANIES]: { get: () => store.dispatch(getCompanies()) },
    [MESSAGES]: {
        get: (params = {}) => store.dispatch(getMessages(params)),
        delete: (id) => store.dispatch(deleteMessage(id)),
    },
    [PARTNERSHIP]: { get: (params = {}) => store.dispatch(getPartnership(params)) },
};
