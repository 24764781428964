import { createAction, createReducer } from '../../helpers';
import { baseUrl } from '../../../constants/api';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ordersLoading } from './OrdersDataDucks';

/** Action types **/
const SET_USERS = 'SET_USERS';
const SET_UPDATE_USERS = 'SET_UPDATE_USERS';
const SET_SINGLE_USER = 'SET_SINGLE_USER';
const SET_USERS_LOADING = 'SET_USERS_LOADING';
const SET_PAGE = 'SET_PAGE';
/** Actions **/
export const setPage = createAction(SET_PAGE);
export const setUsers = createAction(SET_USERS);
export const setSingleUser = createAction(SET_SINGLE_USER);
export const setUpdateUser = createAction(SET_UPDATE_USERS);
export const setUsersLoading = createAction(SET_USERS_LOADING);

export function getUsers(data = {}) {
    return (dispatch) => {
        dispatch(setUsersLoading(true));
        axios({
            method: 'post',
            url: baseUrl.concat('/users/getUsers'),
            data,
            headers: {
                authorization: Cookies.get('token'),
            },
        })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setUsers(res.data.data));
                    dispatch(setPage(data.page || 1));
                }
                dispatch(setUsersLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(setUsersLoading(false));
            });
    };
}
export function getSingleUser(id) {
    return (dispatch) => {
        dispatch(setUsersLoading(true));
        axios({
            method: 'post',
            url: baseUrl.concat('/users/getUsers'),
            data: { id },
            headers: {
                authorization: Cookies.get('token'),
            },
        })
            .then((res) => {
                if (!res.data.error) {
                    const newData = { id, data: res.data.data };
                    dispatch(setSingleUser(newData));
                }
                dispatch(setUsersLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(setUsersLoading(false));
            });
    };
}

export function changeUserStatus(data) {
    return (dispatch) => {
        dispatch(setUsersLoading(true));
        axios
            .post(baseUrl + '/users/changeStatus', data, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setUpdateUser(data));
                }
                dispatch(setUsersLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(setUsersLoading(false));
            });
    };
}
/** Reducer **/
export const usersTableData = createReducer({ users: [], singleUserData: {} }, (state, { value }) => ({
    [SET_USERS]: () => {
        const users = value.users.map((item) => {
            return { ...item, key: item.id };
        });
        return { count: value.count, users };
    },
    [SET_SINGLE_USER]: () => {
        return { singleUserData: value.data };
    },
    [SET_UPDATE_USERS]: () => {
        const { users } = state;
        users.find((row) => row.id === value.id).is_active = value.is_active;
        return { ...state, users };
    },
    [SET_PAGE]: () => ({ ...state, currentPage: value }),
    [SET_USERS_LOADING]: () => ({ ...state, isLoading: value }),
    // [UPDATE_EXAMPLE]: { ...state, ...value },
    // [RESET_EXAMPLE]: initial,
}));
