import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Checkbox } from 'antd';
import { SIDEBAR_NAMES } from '../../../constants/names';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import { SIDEBAR_IDS } from '../../../constants/ids';
import ViewModal from '../PartnershipTable/ViewModal';

function PartnershipTable({ tabId, tableContentData, match }) {
    useEffect(() => {
        const pathName = match.url.split('/')[1].toUpperCase();
        TABLE_CONTENT_CRUDS[SIDEBAR_IDS[pathName]].get();
    }, [tabId]);

    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [viewData, setViewData] = useState({});

    const closeModal = () => {
        setViewData({});
        setViewModalVisible(false);
    };

    const openModal = (row) => {
        setViewData(row);
        setViewModalVisible(true);
    };

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: '0',
        },
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            key: '1',
        },
        {
            title: 'Type',
            dataIndex: 'company_type',
            key: '2',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: '3',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: '4',
        },
        {
            title: 'Director',
            dataIndex: 'company_director',
            key: '5',
        },
        {
            title: 'Brand',
            dataIndex: 'brand_name',
            key: '6',
        },
        {
            title: 'View',
            dataIndex: 'view',
            key: '7',
            render: (text, row) => (
                <Button type="primary" onClick={() => openModal(row)}>
                    View
                </Button>
            ),
        },
    ];

    const { isLoading, data, currentPage, count } = tableContentData;

    return (
        <div>
            <Table
                pagination={{
                    onChange: (page) => TABLE_CONTENT_CRUDS[tabId].get({ page }),
                    position: ['none', 'bottomRight'],
                    hideOnSinglePage: true,
                    pageSize: 10,
                    total: count,
                    current: currentPage,
                }}
                hideOnSinglePage
                columns={tableColumns}
                dataSource={data}
                bordered
                loading={isLoading}
                indentSize={15}
                rowKey={(record) => record.id}
                title={() => SIDEBAR_NAMES[tabId]}
            />
            <ViewModal viewData={viewData} visible={viewModalVisible} onOk={closeModal} onCancel={closeModal} />
        </div>
    );
}

const mapStateToProps = ({ partnershipTableData }) => ({
    tableContentData: partnershipTableData,
});

export default connect(mapStateToProps)(PartnershipTable);
