import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Table } from 'antd';
import moment from 'moment';
import { updateReviewStatus } from '../../../redux/ducks/TableDataDucks/ReviewsTableDucks';
import ViewModal from './ViewModal';

function UserReviewTab({ id, reviews, updateReviewStatus }) {
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [viewData, setViewData] = useState({});

    const closeModal = () => {
        setViewData({});
        setViewModalVisible(false);
    };

    const openModal = (row) => {
        setViewData(row);
        setViewModalVisible(true);
    };

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: '0',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: '1',
        },
        {
            title: 'Product ID',
            dataIndex: 'product_id',
            key: '2',
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: '3',
        },
        {
            title: 'Comment text',
            dataIndex: 'comment_text',
            key: '4',
            width: '200px',
            ellipsis: true,
        },
        {
            title: 'Is active',
            dataIndex: 'is_active',
            key: '6',
            sorter: (a, b) => a.is_active - b.is_active,
            render: (text, row) => (
                <Checkbox
                    checked={row.is_active}
                    onChange={() => updateReviewStatus({ id: row.id, isActive: !row.is_active })}
                />
            ),
        },
        {
            title: 'Create at',
            dataIndex: 'created_at',
            key: '7',
            render: (text, row) => <span>{moment(row.created_at).format('YYYY-MM-DD')}</span>,
        },
        {
            title: 'View',
            dataIndex: 'view',
            key: '8',
            render: (text, row) => (
                <Button type="primary" onClick={() => openModal(row)}>
                    View
                </Button>
            ),
        },
    ];
    return (
        <div>
            <Table
                pagination={{ position: ['none', 'bottomRight'] }}
                columns={tableColumns}
                dataSource={reviews}
                bordered
                loading={false}
                indentSize={15}
                rowKey={(record) => record.id}
            />
            <ViewModal viewData={viewData} visible={viewModalVisible} onOk={closeModal} onCancel={closeModal} />
        </div>
    );
}
const mapDispatchToProps = {
    updateReviewStatus,
};
export default connect(null, mapDispatchToProps)(UserReviewTab);
