import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Rate, Table } from 'antd';
import ViewModal from './ViewModal';

ProductReviewTab.propTypes = {};

function ProductReviewTab({ id, dataRev }) {
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [viewData, setViewData] = useState({});

    const closeModal = () => {
        setViewData({});
        setViewModalVisible(false);
    };

    const openModal = (row) => {
        setViewData(row);
        setViewModalVisible(true);
    };

    const tableColumns = [
        {
            title: 'Comment text',
            dataIndex: 'comment_text',
            key: '0',
            width: '200px',
            ellipsis: true,
        },
        {
            title: 'First name',
            dataIndex: 'first_name',
            key: '1',
        },
        {
            title: 'Last name',
            dataIndex: 'last_name',
            key: '2',
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: '3',
            render: (text, row) => <Rate allowHalf disabled defaultValue={row.rate} />,
        },
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: '4',
        },
        {
            title: 'View',
            dataIndex: 'view',
            key: '5',
            render: (text, row) => (
                <Button type="primary" onClick={() => openModal(row)}>
                    View
                </Button>
            ),
        },
    ];

    return (
        <div>
            <Table
                hideOnSinglePage
                columns={tableColumns}
                dataSource={dataRev}
                bordered
                loading={false}
                indentSize={15}
                rowKey={(record) => record.id}
            />
            <ViewModal viewData={viewData} visible={viewModalVisible} onOk={closeModal} onCancel={closeModal} />
        </div>
    );
}

export default ProductReviewTab;
