import { createAction, createReducer } from '../../helpers';
import { baseUrl } from '../../../constants/api';
import axios from 'axios';
import Cookies from 'js-cookie';

/** Action types **/
const SET_PRODUCTS = 'SET_PRODUCTS';
const SET_PRODUCTS_SEARCH = 'SET_PRODUCTS_SEARCH';
const SET_PAGE = 'SET_PAGE';
const SET_PRODUCTS_COMPANY = 'SET_PRODUCTS_COMPANY';
const SET_UPDATE_PRODUCTS = 'SET_UPDATE_PRODUCTS';
const SET_UPDATE_PRODUCT = 'SET_UPDATE_PRODUCT';
const SET_PRODUCTS_BY_ID = 'SET_PRODUCTS_BY_ID';
const PRODUCTS_LOADING = 'PRODUCTS_LOADING';

/** Actions **/

export const setProducts = createAction(SET_PRODUCTS);
export const setProductsSearch = createAction(SET_PRODUCTS_SEARCH);
export const setPage = createAction(SET_PAGE);
export const setUpdateProducts = createAction(SET_UPDATE_PRODUCTS);
export const setUpdateProduct = createAction(SET_UPDATE_PRODUCT);
export const productsLoading = createAction(PRODUCTS_LOADING);
export const setProductsById = createAction(SET_PRODUCTS_BY_ID);
export const setProductsCompany = createAction(SET_PRODUCTS_COMPANY);

export function getProducts(params = { page: 1 }) {
    return (dispatch) => {
        dispatch(productsLoading(true));
        axios
            .post(baseUrl.concat('/products/get'), params)
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setProducts(res.data.data));
                    dispatch(setPage(params.page || 1));
                }
                dispatch(productsLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(productsLoading(false));
            });
    };
}

export function getSingleProduct(id) {
    return (dispatch) => {
        dispatch(productsLoading(true));
        axios
            .post(
                baseUrl.concat('/products/getSingle'),
                { productID: id, locale: 'en' },
                {
                    headers: {
                        authorization: Cookies.get('token'),
                    },
                }
            )
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setProductsById(res.data.data));
                }
                dispatch(productsLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(productsLoading(false));
            });
    };
}

export function changeProductUpdate(data) {
    return (dispatch) => {
        dispatch(productsLoading(true));
        axios
            .post(baseUrl.concat('/products/update'), data, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setUpdateProduct(data));
                }
                dispatch(productsLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(productsLoading(false));
            });
    };
}

export function searchProduct(data) {
    return (dispatch) => {
        dispatch(productsLoading(true));
        axios
            .post(baseUrl.concat('/products/search'), data, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setProductsSearch(res.data));
                    dispatch(setPage(1));
                }
                dispatch(productsLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(productsLoading(false));
            });
    };
}

export function changeProductsUpdate(data, fieldName) {
    return (dispatch) => {
        dispatch(productsLoading(true));
        axios
            .post(baseUrl.concat('/products/update'), data, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setUpdateProducts({ data, fieldName }));
                }
                dispatch(productsLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(productsLoading(false));
            });
    };
}
export function getProductsCompanies() {
    return (dispatch) => {
        dispatch(productsLoading(true));
        axios
            .post(
                baseUrl.concat('/products/companies'),
                {},
                {
                    headers: {
                        authorization: Cookies.get('token'),
                    },
                }
            )
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setProductsCompany(res.data.data));
                }
                dispatch(productsLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(productsLoading(false));
            });
    };
}

/** Reducer **/
export const productsTableData = createReducer([], (state, { value }) => ({
    [SET_PRODUCTS]: () => {
        const products = value.products.map((item) => {
            return { ...item, key: item.id };
        });
        return { count: value.count, products };
    },
    [SET_PRODUCTS_SEARCH]: () => {
        console.log(value);
        const products = value.data.map((item) => {
            return { ...item, key: item.id };
        });
        return { products, count: 20 };
    },
    [SET_UPDATE_PRODUCTS]: () => {
        const { products } = state;
        products.find((row) => row.id === value.data.id)[value.fieldName] = value.data[value.fieldName];
        return { ...state, products };
    },
    [PRODUCTS_LOADING]: () => ({ ...state, isLoading: value }),
    [SET_PAGE]: () => ({ ...state, currentPage: value }),
}));

export const productsByIDReducer = createReducer({ isLoading: true }, (state, { value }) => ({
    [SET_PRODUCTS_BY_ID]: () => value,
    [SET_UPDATE_PRODUCT]: () => {
        const { product } = state;

        state.product = { ...product, ...value };
        return { ...state };
    },
    [PRODUCTS_LOADING]: () => ({ ...state, isLoading: value }),
}));

export const productsCompanyReducer = createReducer({}, (state, { value }) => ({
    [SET_PRODUCTS_COMPANY]: () => value,
}));
