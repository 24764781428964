import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { changeProductUpdate } from '../../../redux/ducks/TableDataDucks/ProductsDataDucks';
import { Spin, Form, Input, Button, Select, Upload, Checkbox, Grid, Rate, message, Popover, DatePicker } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { baseUrl, imageUrl } from '../../../constants/api';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import { SIDEBAR_IDS } from '../../../constants/ids';
import Cookies from 'js-cookie';
import axios from 'axios';
import moment from 'moment';
const { Option } = Select;

const EditableTable = ({ dataSource, categoriesTableData, changeProductUpdate, productsByIDReducer }) => {
    const [form] = Form.useForm();
    if (dataSource) {
        const { Name = '', Count = '' } = dataSource.params || {};
        dataSource.paramName = Name;
        dataSource.paramCount = Count;
        if (dataSource.sale_date) {
            dataSource.sale_date = moment(dataSource.sale_date);
        }
        console.log(dataSource.sale_date);
        form.setFieldsValue(dataSource);
    }
    const { isLoading } = productsByIDReducer;

    useEffect(() => {
        TABLE_CONTENT_CRUDS[SIDEBAR_IDS.CATEGORIES].get();
    }, []);

    const [componentSize, setComponentSize] = useState('default');
    const onFormLayoutChange = (info) => {
        console.log(info);
        form.setFieldsValue(info);
    };
    const uploadAction = (options) => {
        const { onSuccess } = options;
        const data = new FormData();
        data.append('avatar', options.file);
        data.append('id', dataSource.id);
        const config = {
            headers: {
                authorization: Cookies.get('token'),
            },
        };
        axios
            .post(options.action, data, config)
            .then((res) => {
                onSuccess(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const picture = {
        name: 'avatar',
        action: baseUrl + '/products/update',
        customRequest: uploadAction,
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const categoryList = [];
    categoriesTableData.categories.length > 0 &&
        categoriesTableData.categories.forEach((item) => {
            const { subCategories, ...row } = item;
            if (subCategories.length > 0) {
                row.children = subCategories;
                subCategories.forEach((cat, i) => {
                    categoryList.push({ value: cat.id, label: cat.title_am });
                    if (cat.subCategories.length > 0) {
                        row.children[i].children = cat.subCategories;
                    }
                });
            }
            categoryList.push({ value: item.id, label: item.title_am });
        });

    return (
        <>
            {isLoading ? (
                <Spin />
            ) : (
                dataSource && (
                    <div className="product-info-unipack" style={{ marginLeft: 10 }}>
                        <div>Avatar :</div>
                        <div>
                            <span>
                                <img
                                    style={{ width: '150px', margin: '5px 10px 10px 5px' }}
                                    alt="example"
                                    src={imageUrl + dataSource.avatar}
                                />
                            </span>
                            <Upload {...picture}>
                                <Button>
                                    <UploadOutlined /> Upload
                                </Button>
                            </Upload>
                        </div>
                        <Form
                            form={form}
                            layout="horizontal"
                            initialValues={{ size: componentSize }}
                            onValuesChange={(info) => onFormLayoutChange(info)}
                        >
                            <Form.Item label="Category:" name="category_id">
                                <Select style={{ width: 500 }}>
                                    {categoryList.map((item) => (
                                        <Option value={item.value}> {item.label}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item hidden label="ID:" name="id">
                                <Input style={{ width: 200 }} />
                            </Form.Item>
                            <Form.Item label="Params Name:" name="paramName">
                                <Input style={{ width: 200 }} />
                            </Form.Item>
                            <Form.Item label="Params Count:" name="paramCount">
                                <Input style={{ width: 200 }} />
                            </Form.Item>
                            <Form.Item label="Title ARM:" name="title_am">
                                <Input style={{ width: 200 }} />
                            </Form.Item>
                            <Form.Item label="Title ENG:" name="title_en">
                                <Input style={{ width: 200 }} />
                            </Form.Item>
                            <Form.Item label="Title RUS:" name="title_ru">
                                <Input style={{ width: 200 }} />
                            </Form.Item>
                            <Form.Item label="Description ARM:" name="description_am" style={{ maxWidth: 600 }}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={dataSource.description_am || ''}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        form.setFieldsValue({ description_am: data });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Description EN:" name="description_en" style={{ maxWidth: 600 }}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={dataSource.description_en || ''}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        form.setFieldsValue({ description_en: data });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Description RU:" name="description_ru" style={{ maxWidth: 600 }}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={dataSource.description_ru || ''}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        form.setFieldsValue({ description_ru: data });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Short Description ARM:"
                                name="description_short_am"
                                style={{ maxWidth: 600 }}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Short Description ENG:"
                                name="description_short_en"
                                style={{ maxWidth: 600 }}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Short Description RUS:"
                                name="description_short_ru"
                                style={{ maxWidth: 600 }}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item label="Price:" name="price">
                                <Input style={{ width: 100 }} />
                            </Form.Item>
                            <Form.Item label="Discounted price:" name="discounted_price">
                                <Input style={{ width: 100 }} />
                            </Form.Item>
                            <Form.Item label="Wholesale price:" name="wholesale_price">
                                <Input style={{ width: 100 }} />
                            </Form.Item>
                            <Form.Item label="Quantity:" name="quantity">
                                <Input type={'number'} style={{ width: 100 }} />
                            </Form.Item>
                            <Form.Item label="Article ID:" name="article_id">
                                {dataSource.article_id || 'empty'}
                            </Form.Item>
                            <Form.Item label="Stock ID:" name="stock_id">
                                {dataSource.stock_id}
                            </Form.Item>
                            <Form.Item label="Reviews:">{dataSource.reviews}</Form.Item>
                            <Form.Item label="Is Active:" name="is_active" valuePropName={'checked'}>
                                <Checkbox />
                            </Form.Item>
                            <Form.Item label="Is Sale:" name="is_sale" valuePropName={'checked'}>
                                <Checkbox />
                            </Form.Item>
                            <Form.Item label="Is New:" name="is_new" valuePropName={'checked'}>
                                <Checkbox />
                            </Form.Item>
                            <Form.Item label="Is Featured:" name="is_featured" valuePropName={'checked'}>
                                <Checkbox />
                            </Form.Item>
                            <Form.Item label="Weekly:" name="weekly" valuePropName={'checked'}>
                                <Checkbox />
                            </Form.Item>
                            <Form.Item label="Rate:" valuePropName={dataSource.weekly}>
                                <Rate allowHalf disabled defaultValue={dataSource.rate} />
                            </Form.Item>
                            <Form.Item label="Sale Date:" name="sale_date">
                                <DatePicker />
                            </Form.Item>
                        </Form>
                        <Button
                            onClick={() => {
                                form.validateFields()
                                    .then((values) => {
                                        values.is_active = values.is_active ? 1 : 0;
                                        values.is_sale = values.is_sale ? 1 : 0;
                                        values.is_new = values.is_new ? 1 : 0;
                                        values.is_featured = values.is_featured ? 1 : 0;
                                        // values.sale_date = values.sale_date.format('YYYY-MM-DD');
                                        values.weekly = values.weekly ? 1 : 0;
                                        values.params = { Name: values.paramName, Count: values.paramCount };
                                        delete values.paramName;
                                        delete values.paramCount;
                                        changeProductUpdate(values);
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                            }}
                            type="primary"
                            style={{ width: 300 }}
                        >
                            Save
                        </Button>
                    </div>
                )
            )}
        </>
    );
};

const mapStateToProps = ({ productsByIDReducer, categoriesTableData }) => ({
    productsByIDReducer,
    categoriesTableData,
});

const mapDispatchToProps = { changeProductUpdate };

export default connect(mapStateToProps, mapDispatchToProps)(EditableTable);
