import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import OrderInfoTab from './OrderInfoTab';
import OrderProductTab from './OrderProductTab';

const { TabPane } = Tabs;

function OrderTabs({ data }) {
    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Info" key="1">
                    <OrderInfoTab data={data || null} />
                </TabPane>
                <TabPane tab="Products" key="2">
                    <OrderProductTab data={data?.orders || null} />
                </TabPane>
            </Tabs>
        </div>
    );
}

export default OrderTabs;
