import { createAction, createReducer } from '../../helpers';
import { baseUrl } from '../../../constants/api';
import axios from 'axios';
import Cookies from 'js-cookie';
import { setUsersLoading } from './AdminUsersTableDucks';

/** Action types **/
const SET_BANNERS = 'SET_BANNERS';
const BANNERS_LOADING = 'BANNERS_LOADING';

/** Actions **/

export const setBanners = createAction(SET_BANNERS);
export const bannersLoading = createAction(BANNERS_LOADING);

export function getBanners(type = 'web') {
    return (dispatch) => {
        dispatch(bannersLoading(true));
        axios({
            method: 'post',
            url: baseUrl.concat('/banner/get'),
        })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setBanners(res.data.data[type]));
                }
                dispatch(bannersLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(bannersLoading(false));
            });
    };
}

export function setBanner(data) {
    return (dispatch) => {
        axios
            .post(baseUrl + '/banner/set', data.values, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(getBanners(data.type));
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch(bannersLoading(false));
            });
    };
}

export function updateBanner(data) {
    return (dispatch) => {
        axios
            .post(baseUrl + '/banner/update', data, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(getBanners(data.get('options')));
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch(bannersLoading(false));
            });
    };
}

export function deleteBanner(data) {
    return (dispatch) => {
        axios
            .post(
                baseUrl + '/banner/delete',
                { id: data.id },
                {
                    headers: {
                        authorization: Cookies.get('token'),
                    },
                }
            )
            .then((res) => {
                if (!res.data.error) {
                    dispatch(getBanners(data.type));
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch(bannersLoading(false));
            });
    };
}

/** Reducer **/
export const bannersTableData = createReducer({ banners: {}, isLoading: true }, (state, { value }) => ({
    [SET_BANNERS]: () => {
        return { ...state, banners: value };
    },
    [BANNERS_LOADING]: () => ({ ...state, isLoading: value }),
    // [UPDATE_EXAMPLE]: ()=> ({ ...state, ...value }),
    // [RESET_EXAMPLE]: ()=> initial,
}));
