import React, { useEffect } from 'react';
import { Form, Modal } from 'antd';

function ViewModal({ visible, viewData, onOk, onCancel }) {
    const { id, name, email, title, message } = viewData;
    return (
        <Modal title={false} visible={visible} onOk={onOk} onCancel={onCancel}>
            <Form name="nest-messages">
                <Form.Item label="ID">{id}</Form.Item>
                <Form.Item label="Name">{name}</Form.Item>
                <Form.Item label="Email">{email}</Form.Item>
                <Form.Item label="Title">{title}</Form.Item>
                {message && <Form.Item label="Message">{message}</Form.Item>}
            </Form>
        </Modal>
    );
}

export default ViewModal;
