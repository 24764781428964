import React, { useState } from 'react';
import { Modal, Form, Input, Checkbox, Select, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getBase64 } from '../ProductsTable/ProductPicturesTab';

function CollectionCreateForm({ visible, onCreate, onCancel, parentIds }) {
    const [form] = Form.useForm();
    const [imgPath, changePath] = useState();

    const onFileChange = async ({ file }) => {
        if (!file.url && !file.preview) {
            const preview = await getBase64(file);
            changePath(preview);
        }
        form.setFieldsValue({ path: file });
    };

    return (
        <Modal
            visible={visible}
            title="Create a new collection"
            okText="Create"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        form.resetFields();
                        const fd = new FormData();
                        values.is_home = !!values.is_home; //TODO change to ant design way
                        Object.entries(values).forEach((value) => {
                            fd.append(value[0], value[1]);
                        });
                        onCreate(fd);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form form={form} layout="horizontal" name="form_in_modal" initialValues={{}}>
                <Form.Item
                    name="name_am"
                    label="Name Arm"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the title of armenian!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="name_ru"
                    label="Name Rus"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the title of russian!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="name_en"
                    label="Name Eng"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the title of english!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="icon"
                    label="Icon"
                    rules={[
                        {
                            required: true,
                            message: 'Please upload icon!',
                        },
                    ]}
                >
                    <>
                        <img src={imgPath} width="120" style={{ marginRight: '20px' }} />
                        <Upload onChange={onFileChange} beforeUpload={() => false}>
                            <Button>
                                <UploadOutlined /> Upload
                            </Button>
                        </Upload>
                    </>
                </Form.Item>

                <Form.Item name="is_home" label="Show?" valuePropName={'checked'}>
                    <Checkbox checked={false} />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CollectionCreateForm;
