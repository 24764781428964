import { createAction, createReducer } from '../../helpers';
import { baseUrl } from '../../../constants/api';
import axios from 'axios';
import Cookies from 'js-cookie';

/** Action types **/
const SET_MESSAGES = 'SET_MESSAGES';
const MESSAGES_LOADING = 'MESSAGES_LOADING';
const SET_PAGE = 'SET_PAGE';

/** Actions **/

export const setMessages = createAction(SET_MESSAGES);
export const setPage = createAction(SET_PAGE);
export const messagesLoading = createAction(MESSAGES_LOADING);

export function getMessages(params = { page: 1 }) {
    return (dispatch) => {
        dispatch(messagesLoading(true));
        axios
            .post(baseUrl.concat('/message/get'), params, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.data.error && res.data.data.count > 0) {
                    dispatch(setMessages(res.data.data));
                    dispatch(setPage(params.page || 1));
                }
                dispatch(messagesLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(messagesLoading(false));
            });
    };
}

export function deleteMessage(id) {
    return (dispatch) => {
        dispatch(messagesLoading(true));
        axios
            .post(baseUrl + '/message/delete', id, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(getMessages());
                }
                dispatch(messagesLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(messagesLoading(false));
            });
    };
}

/** Reducer **/
export const messagesTableData = createReducer({ messages: [], isLoading: true }, (state, { value }) => ({
    [SET_MESSAGES]: () => {
        console.log(value);
        const messages = value.messages.map((item) => {
            return { ...item, key: item.id };
        });
        return { count: value.count, messages };
    },
    [MESSAGES_LOADING]: () => ({ ...state, isLoading: value }),
    [SET_PAGE]: () => ({ ...state, currentPage: value }),
}));
