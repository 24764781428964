import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import ProductInfoTab from './ProductInfoTab';
import ProductPicturesTab from './ProductPicturesTab';
import ProductReviewTab from './ProductReviewTab';
import EditableTable from './ProductInfoTab';

const { TabPane } = Tabs;

function ProductsTabs({ data, id }) {
    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Info" key="1">
                    <EditableTable dataSource={data?.product} id={id} />
                </TabPane>
                <TabPane tab="Pictures" key="2">
                    <ProductPicturesTab dataPic={data.product?.pictures} id={id} />
                </TabPane>
                <TabPane tab="Reviews" key="3">
                    <ProductReviewTab dataRev={data?.reviews} />
                </TabPane>
            </Tabs>
        </div>
    );
}

export default ProductsTabs;
