import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import UserOrdersTab from './UserOrdersTab';
import { SIDEBAR_IDS } from '../../../constants/ids';

OrdersTable.propTypes = {
    tabId: PropTypes.number.isRequired,
};

function OrdersTable({ tabId, tableContentData, match }) {
    useEffect(() => {
        const pathName = match.url.split('/')[1].toUpperCase();
        TABLE_CONTENT_CRUDS[SIDEBAR_IDS[pathName]].get({ type: 'all' });
    }, [tabId]);

    const { isLoading, count, orders, currentPage } = tableContentData;

    return (
        <UserOrdersTab
            tabId={tabId}
            showDataPicker={true}
            count={count}
            currentPage={currentPage}
            orders={orders}
            isLoading={isLoading}
        />
    );
}

const mapStateToProps = ({ ordersTableData }) => ({ tableContentData: ordersTableData });

export default connect(mapStateToProps, null)(OrdersTable);
