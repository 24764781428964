import { createAction, createReducer } from '../../helpers';

/** Action types **/
const SET_TAB_ID = 'SET_TAB_ID';

/** Actions **/

export const setTabId = createAction(SET_TAB_ID);

export function changeTabId(id) {
    return (dispatch) => {
        dispatch(setTabId(id));
    };
}

/** Reducer **/
export const tabIdReducer = createReducer(0, (state, { value }) => ({
    [SET_TAB_ID]: () => value,
    // [UPDATE_EXAMPLE]: { ...state, ...value },
    // [RESET_EXAMPLE]: initial,
}));
