import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { SIDEBAR_NAMES } from '../../../constants/names';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import moment from 'moment';
import CollectionCreateForm from './CreateNewModal';
import EditModal from './EditModal';
import {
    updateCategory,
    changeCategoryStatus,
    setCategory,
} from '../../../redux/ducks/TableDataDucks/CategoriesDataDucks';

CategoriesTable.propTypes = {
    tabId: PropTypes.number.isRequired,
};

const nestLevels = {
    '0': 'category_unipack',
    '1': 'subCategory_unipack',
    '2': 'sub-SubCategory_unipack',
};

function CategoriesTable({ tabId, tableContentData, updateCategory, changeCategoryStatus, setCategory, ...rest }) {
    useEffect(() => {
        TABLE_CONTENT_CRUDS[tabId].get();
    }, [tabId]);

    // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [newModalVisible, setNewModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editData, setEditData] = useState({});

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: '0',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Title ARM',
            dataIndex: 'title_am',
            key: '1',
        },
        {
            title: 'Parent ID',
            dataIndex: 'parent_id',
            key: '2',
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: '3',
            sorter: (a, b) => a.priority - b.priority,
        },
        {
            title: 'Enable / Disable',
            dataIndex: 'is_active',
            key: '4',
            sorter: (a, b) => a.is_active - b.is_active,
            render: (text, row) => (
                <Checkbox checked={row.is_active} onChange={() => changeCategoryStatus({ id: row.id })} />
            ),
        },
        {
            title: 'Create at',
            dataIndex: 'created_at',
            key: '5',
            render: (text, row) => <span>{moment(row.created_at).format('YYYY-MM-DD')}</span>,
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: '6',
            render: (text, row) => (
                <Button type="primary" onClick={() => editModal(row)}>
                    Edit
                </Button>
            ),
        },
    ];

    const data = [];
    const parentIds = [{ value: 0, label: 'No Parent' }];

    const { isLoading, categories } = tableContentData;

    const onCreate = (values) => {
        setCategory(values);
        setNewModalVisible(false);
    };

    const editCategory = (values) => {
        updateCategory(values);
        setEditModalVisible(false);
    };

    const onCancel = () => {
        setNewModalVisible(false);
    };

    const onCancelEdit = () => {
        setEditData({});
        setEditModalVisible(false);
    };

    const editModal = (row) => {
        setEditData(row);
        setEditModalVisible(true);
    };

    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: (event, selectedEvents) => {
    //         const events = selectedEvents.map((category) => category.id);
    //         setSelectedRowKeys(events);
    //         // console.log('event:', events, 'selectedEvents', selectedEvents);
    //     },
    // };

    tableContentData.categories.length > 0 &&
        tableContentData.categories.forEach((item) => {
            const { subCategories, ...row } = item;
            if (subCategories.length > 0) {
                row.children = subCategories;
                subCategories.forEach((cat, i) => {
                    parentIds.push({ value: cat.id, label: cat.title_am });
                    if (cat.subCategories.length > 0) {
                        row.children[i].children = cat.subCategories;
                    }
                });
            }
            data.push({ ...row, key: item.id });
            parentIds.push({ value: item.id, label: item.title_am });
        });
    return (
        <div>
            <Button onClick={() => setNewModalVisible(true)} type="primary" style={{ marginBottom: 16 }}>
                Create New
            </Button>

            <CollectionCreateForm
                visible={newModalVisible}
                onCreate={onCreate}
                onCancel={onCancel}
                parentIds={parentIds}
            />

            <EditModal
                visible={editModalVisible}
                updateCategory={editCategory}
                onCancelEdit={onCancelEdit}
                parentIds={parentIds}
                editData={editData}
            />

            <Table
                hideOnSinglePage
                columns={tableColumns}
                dataSource={data.length > 0 ? data : null}
                rowClassName={(record) => nestLevels[record.nest_level]}
                bordered
                loading={isLoading}
                indentSize={15}
                rowKey={(record) => record.id}
                title={() => SIDEBAR_NAMES[tabId]}
            />
        </div>
    );
}

const mapStateToProps = ({ categoriesTableData }) => ({
    tableContentData: categoriesTableData,
});

const mapDispatchToProps = {
    updateCategory,
    changeCategoryStatus,
    setCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesTable);
