import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Table, Input, Rate, Select, Form } from 'antd';
import { SIDEBAR_NAMES } from '../../../constants/names';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
import { changeProductsUpdate, getProductsCompanies } from '../../../redux/ducks/TableDataDucks/ProductsDataDucks';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { imageUrl } from '../../../constants/api';
import { SIDEBAR_IDS } from '../../../constants/ids';

function ProductsTable({
    tabId,
    tableContentData,
    changeProductsUpdate,
    categoriesTableData,
    getProductsCompanies,
    productsCompanyReducer,
}) {
    useEffect(() => {
        TABLE_CONTENT_CRUDS[SIDEBAR_IDS.CATEGORIES].get();
        TABLE_CONTENT_CRUDS[tabId].get({ isActive: 'all', page: currentPage || 1 });
        getProductsCompanies();
    }, [getProductsCompanies, tabId]);
    const { products, count, isLoading, currentPage } = tableContentData;
    const categoryList = [{ value: 'all', label: 'all' }];
    const companyList = [{ value: 'all', label: 'all' }];
    const isActiveList = [
        { value: 'all', label: 'all' },
        { value: 0, label: 'passive' },
        { value: 1, label: 'active' },
    ];

    productsCompanyReducer?.length &&
        productsCompanyReducer.forEach((item) => {
            companyList.push({ value: item.id || 1, label: item.name_am });
        });

    categoriesTableData.categories.length > 0 &&
        categoriesTableData.categories.forEach((item) => {
            const { subCategories, ...row } = item;
            if (item.subCategories.length > 0) {
                row.children = item.subCategories;
                const subId = row.children.find((elem) => elem.id);
                categoryList.push({ value: subId.id, label: subId.title_am });
            }
            categoryList.push({ value: item.id, label: item.title_am });
        });

    const [productsFilter, setProductsFilter] = useState({
        isActive: 'all',
        isSale: 0,
        isNew: 0,
        isFeatured: 0,
        weekly: 0,
        companies: '',
    });

    const onSelectFilterChange = (value) => {
        const newProdFilter = { ...productsFilter, ...value };
        setProductsFilter({ ...newProdFilter });
    };

    const searchText = (value, key) => {
        if (value.trim().length > 1) {
            TABLE_CONTENT_CRUDS[tabId].searchProduct(value.trimLeft());
        } else if (value === '') {
            TABLE_CONTENT_CRUDS[tabId].get(productsFilter);
        }
    };

    const searchOnClick = () => {
        TABLE_CONTENT_CRUDS[tabId].get(productsFilter);
    };

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: '0',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
            render: (text, row) => (
                <span style={{ marginRight: 16 }}>
                    <Link to={'/products/' + row.id}>{row.id}</Link>
                </span>
            ),
        },
        {
            title: 'Title ARM',
            dataIndex: 'title_am',
            key: '1',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: '2',
        },
        {
            title: 'Discounted Price',
            dataIndex: 'discounted_price',
            key: '3',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: '4',
        },
        {
            title: 'Category ID',
            dataIndex: 'category_id',
            key: '5',
            render: (text, row) => <span>{categoryList.find((item) => item.value === row.category_id)?.label}</span>,
        },
        {
            title: 'Avatar',
            dataIndex: 'avatar',
            key: '6',
            render: (text, row) => <img src={imageUrl + row.avatar} width="50" />,
        },
        {
            title: 'Article ID',
            dataIndex: 'article_id',
            key: '7',
        },
        {
            title: 'Is Active',
            dataIndex: 'is_active',
            key: '8',
            sorter: (a, b) => a.is_active - b.is_active,
            render: (text, row) => (
                <Checkbox
                    checked={row.is_active}
                    disabled
                    // onChange={() =>
                    //     changeProductsUpdate({ id: row.id, is_active: row.is_active === 0 ? 1 : 0 }, 'is_active')
                    // }
                />
            ),
        },
        {
            title: 'Is Sale',
            dataIndex: 'is_sale',
            key: '9',
            sorter: (a, b) => a.is_sale - b.is_sale,
            render: (text, row) => (
                <Checkbox
                    checked={row.is_sale}
                    disabled
                    // onChange={() => changeProductsUpdate({ id: row.id, is_sale: row.is_sale === 0 ? 1 : 0 }, 'is_sale')}
                />
            ),
        },
        {
            title: 'Is New',
            dataIndex: 'is_new',
            key: '10',
            sorter: (a, b) => a.is_new - b.is_new,
            render: (text, row) => (
                <Checkbox
                    checked={row.is_new}
                    disabled
                    // onChange={() => changeProductsUpdate({ id: row.id, is_new: row.is_new === 0 ? 1 : 0 }, 'is_new')}
                />
            ),
        },
        {
            title: 'Is Featured',
            dataIndex: 'is_featured',
            key: '11',
            sorter: (a, b) => a.is_featured - b.is_featured,
            render: (text, row) => (
                <Checkbox
                    checked={row.is_featured}
                    disabled
                    // onChange={() =>
                    //     changeProductsUpdate({ id: row.id, is_featured: row.is_featured === 0 ? 1 : 0 }, 'is_featured')
                    // }
                />
            ),
        },
        {
            title: 'Weekly',
            dataIndex: 'weekly',
            key: '12',
            sorter: (a, b) => a.weekly - b.weekly,
            render: (text, row) => (
                <Checkbox
                    checked={row.weekly}
                    disabled
                    // onChange={() => changeProductsUpdate({ id: row.id, weekly: row.weekly === 0 ? 1 : 0 }, 'weekly')}
                />
            ),
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: '13',
            render: (text, row) => <Rate allowHalf disabled defaultValue={row.rate} />,
        },
        {
            title: 'Reviews',
            dataIndex: 'reviews',
            key: '14',
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: '15',
            render: (text, row) => (
                <Button type="primary">
                    <Link to={'/products/' + row.id}>Edit</Link>
                </Button>
            ),
        },
    ];

    return (
        <div>
            <div className="products__search__unipack">
                <div>
                    <div className="products__search__unipack-div">
                        Input text:
                        <Input onChange={(e) => searchText(e.target.value)} />
                    </div>
                    <span className="products__search__unipack-div">
                        Is Sale:{' '}
                        <Checkbox onChange={(e) => onSelectFilterChange({ isSale: Number(e.target.checked) })} />
                    </span>
                    <span className="products__search__unipack-div">
                        Is New: <Checkbox onChange={(e) => onSelectFilterChange({ isNew: Number(e.target.checked) })} />
                    </span>
                    <span className="products__search__unipack-div">
                        Is Featured:{' '}
                        <Checkbox onChange={(e) => onSelectFilterChange({ isFeatured: Number(e.target.checked) })} />
                    </span>
                    <span className="products__search__unipack-div">
                        Weekly:{' '}
                        <Checkbox onChange={(e) => onSelectFilterChange({ weekly: Number(e.target.checked) })} />
                    </span>
                    <span className="products__search__unipack-div">
                        Is Active:{' '}
                        <Select
                            style={{ width: '100px' }}
                            onChange={(value) => onSelectFilterChange({ isActive: value })}
                            options={isActiveList}
                            defaultValue={'all'}
                        />
                    </span>
                    <span className="products__search__unipack-div">
                        Company:
                        <Select
                            style={{ width: '200px' }}
                            onChange={(value) => onSelectFilterChange({ companies: value })}
                            options={companyList}
                            placeholder="all"
                        />
                    </span>
                    <span className="products__search__unipack-div">
                        Category:{' '}
                        <Select
                            style={{ width: '200px' }}
                            onChange={(value) => onSelectFilterChange({ categoryId: value + '' })}
                            options={categoryList}
                            placeholder="all"
                        />
                    </span>
                </div>
                <span className="products__search__unipack-div">
                    <Button onClick={searchOnClick} type="primary" icon={<SearchOutlined />}>
                        Search
                    </Button>
                </span>
            </div>
            <Table
                pagination={{
                    onChange: (page) => TABLE_CONTENT_CRUDS[tabId].get({ ...productsFilter, page }),
                    position: ['none', 'bottomRight'],
                    hideOnSinglePage: true,
                    pageSize: 20,
                    total: count,
                    current: currentPage,
                }}
                hideOnSinglePage
                columns={tableColumns}
                dataSource={products}
                bordered
                loading={isLoading}
                title={() => SIDEBAR_NAMES[tabId]}
            />
        </div>
    );
}

const mapStateToProps = ({ productsTableData, categoriesTableData, productsCompanyReducer }) => ({
    categoriesTableData,
    productsCompanyReducer,
    tableContentData: productsTableData,
});

const mapDispatchToProps = {
    changeProductsUpdate,
    getProductsCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTable);
