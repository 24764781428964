import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Checkbox, Select, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getBase64 } from '../ProductsTable/ProductPicturesTab';

const { Option } = Select;

function CreateNewBanner({ visible, onCreate, onCancel, type }) {
    const [form] = Form.useForm();

    const [imgPath, changePath] = useState();

    const onFileChange = async ({ file }) => {
        if (!file.url && !file.preview) {
            const preview = await getBase64(file);
            changePath(preview);
        }
        form.setFieldsValue({ path: file });
    };

    return (
        <Modal
            visible={visible}
            title="Create a new banner"
            okText="Create"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        form.resetFields();
                        const fd = new FormData();
                        values.is_active = !!values.is_active; //TODO change to ant design way
                        values.priority = values.priority || 10; //TODO change to ant design way
                        values.options = type; //TODO change to ant design way
                        Object.entries(values).forEach((value) => {
                            fd.append(value[0], value[1]);
                        });
                        onCreate(fd);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="horizontal"
                name="form_in_modal"
                initialValues={{
                    site_location: 'top',
                    page: 'home',
                    language: 'en',
                    link: '',
                    active: 1,
                    priority: 10,
                }}
            >
                <Form.Item name="options" style={{ visibility: 'hidden', height: 0 }}>
                    <Input type={'hidden'} />
                </Form.Item>

                <Form.Item
                    name="site_location"
                    label="Position"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the position!',
                        },
                    ]}
                >
                    <Select style={{ width: 100 }}>
                        <Option value="top">top</Option>
                        <Option value="left">left</Option>
                        <Option value="bottom">right</Option>
                        <Option value="middle">middle</Option>
                    </Select>
                </Form.Item>

                <Form.Item name="active" label="Is Active?" valuePropName={'checked'}>
                    <Checkbox checked={false} />
                </Form.Item>
                <Form.Item
                    name="language"
                    label="Language"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the language!',
                        },
                    ]}
                >
                    <Select defaultValue="am" style={{ width: 100 }}>
                        <Option value="am">am</Option>
                        <Option value="ru">ru</Option>
                        <Option value="en">en</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="page"
                    label="Page"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the page!',
                        },
                    ]}
                >
                    <Select defaultValue="home" style={{ width: 100 }}>
                        <Option value="home">home</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="link"
                    label="Link"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the link!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="priority"
                    label="Priority"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the priority!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="path"
                    label="Path"
                    rules={[
                        {
                            required: true,
                            message: 'Please upload banner!',
                        },
                    ]}
                >
                    <>
                        <img src={imgPath} width="120" style={{ marginRight: '20px' }} />
                        <Upload onChange={onFileChange} beforeUpload={() => false}>
                            <Button>
                                <UploadOutlined /> Upload
                            </Button>
                        </Upload>
                    </>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CreateNewBanner;
