import React, { useState, useEffect } from 'react';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { baseUrl, imageUrl } from '../../../constants/api';
import Cookies from 'js-cookie';
import axios from 'axios';

ProductPicturesTab.propTypes = {};

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

function ProductPicturesTab({ dataPic, id }) {
    const [imgData, setImgData] = useState({
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
        fileList: dataPic.map((pic, i) => {
            return { url: imageUrl + pic.url, uid: i, preview: imageUrl + pic.url };
        }),
    });

    const handleCancel = () =>
        setImgData({
            fileList,
            previewVisible: false,
        });

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setImgData({
            previewImage: file.url || file.preview,
            previewVisible: true,
            fileList,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    const handleChange = ({ fileList }) => setImgData({ fileList });

    const { previewVisible, previewImage, fileList, previewTitle } = imgData;
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const handleRemove = (file) => {
        console.log(file, 'remove');
        const data = {
            photoUrl: file.preview,
            productID: id,
        };
        const config = {
            headers: {
                authorization: Cookies.get('token'),
            },
        };
        console.log(data, 'data:remove');
        axios
            .post(baseUrl + '/products/deletePhoto', data, config)
            .then((res) => {
                // onSuccess(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const uploadAction = (file) => {
        console.log(file, 'upload');
        const data = new FormData();
        data.append('photo', file);
        data.append('product_id', id);
        const config = {
            headers: {
                authorization: Cookies.get('token'),
            },
        };
        console.log(data, 'upload:data');

        axios
            .post(baseUrl + '/products/upload', data, config)
            .then((res) => {
                // onSuccess(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    console.log(fileList);
    return (
        <div className="clearfix">
            <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                action={uploadAction}
                onRemove={handleRemove}
            >
                {fileList.length >= 10 ? null : uploadButton}
            </Upload>
            <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </div>
    );
}

export default ProductPicturesTab;
