import { createAction, createReducer } from '../../helpers';
import { baseUrl } from '../../../constants/api';
import axios from 'axios';
import Cookies from 'js-cookie';

/** Action types **/
const SET_GIFTS = 'SET_GIFTS';
const GIFTS_LOADING = 'GIFTS_LOADING';

/** Actions **/

export const setGifts = createAction(SET_GIFTS);
export const giftsLoading = createAction(GIFTS_LOADING);

export function getGifts() {
    return (dispatch) => {
        dispatch(giftsLoading(true));
        axios({
            method: 'post',
            url: baseUrl.concat('/gift/get'),
        })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(setGifts(res.data.data));
                }
                dispatch(giftsLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(giftsLoading(false));
            });
    };
}

export function createGifts(data) {
    return (dispatch) => {
        axios
            .post(baseUrl + '/gift/create', data, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(getGifts());
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch(giftsLoading(false));
            });
    };
}

export function updateGifts(data) {
    return (dispatch) => {
        axios
            .post(baseUrl + '/gift/update', data, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(getGifts());
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch(giftsLoading(false));
            });
    };
}

export function deleteGifts(data) {
    return (dispatch) => {
        axios
            .post(baseUrl + '/gift/delete', data, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                if (!res.data.error) {
                    dispatch(getGifts());
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch(giftsLoading(false));
            });
    };
}

/** Reducer **/
export const giftsTableData = createReducer({ gifts: [], isLoading: true }, (state, { value }) => ({
    [SET_GIFTS]: () => {
        return { ...state, gifts: value };
    },
    [GIFTS_LOADING]: () => ({ ...state, isLoading: value }),
    // [UPDATE_EXAMPLE]: ()=> ({ ...state, ...value }),
    // [RESET_EXAMPLE]: ()=> initial,
}));
