import { createAction, createReducer } from '../helpers';

const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
const SET_IS_LOADING = 'SET_IS_LOADING';

export const setIsAuthenticated = createAction(SET_IS_AUTHENTICATED);
export const setIsLoading = createAction(SET_IS_LOADING);

export const authentication = createReducer({ isAuthenticated: false, isLoading: true }, (state, { value }) => ({
    [SET_IS_AUTHENTICATED]: () => ({ ...state, isAuthenticated: value }),
    [SET_IS_LOADING]: () => ({ ...state, isLoading: value }),
}));
