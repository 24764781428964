import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { imageUrl } from '../../../constants/api';

const actions = ['Enable', 'Disable'];

function UserWishListTab({ id, wishlist }) {
    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: '0',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Avatar',
            dataIndex: 'avatar',
            key: '1',
            render: (text, row) => <img src={imageUrl + row.avatar} width="50" />,
        },
        {
            title: 'Category ID',
            dataIndex: 'category_id',
            key: '2',
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: '3',
            render: (text, row) => <span>{moment(row.created_at).format('YYYY-MM-DD')}</span>,
        },
        {
            title: 'Description AM',
            dataIndex: 'description_am',
            key: '4',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: '5',
        },
        {
            title: 'Title AM',
            dataIndex: 'title_am',
            key: '6',
        },
    ];
    return (
        <div>
            <Table
                pagination={{ position: ['none', 'bottomRight'] }}
                columns={tableColumns}
                dataSource={wishlist}
                bordered
                loading={false}
                indentSize={15}
                rowKey={(record) => record.id}
            />
        </div>
    );
}

export default UserWishListTab;
