import { createAction, createReducer } from '../../helpers';
import { baseUrl } from '../../../constants/api';
import axios from 'axios';
import Cookies from 'js-cookie';

/** Action types **/
const SET_COMPANIES = 'SET_COMPANIES';
const COMPANIES_LOADING = 'COMPANIES_LOADING';

/** Actions **/

export const setCompanies = createAction(SET_COMPANIES);
export const companiesLoading = createAction(COMPANIES_LOADING);

export function getCompanies() {
    return (dispatch) => {
        dispatch(companiesLoading(true));
        axios
            .post(
                baseUrl.concat('/products/companies'),
                {},
                {
                    headers: {
                        authorization: Cookies.get('token'),
                    },
                }
            )
            .then((res) => {
                if (!res.data.error && res.data.data.length > 0) {
                    dispatch(setCompanies(res.data.data));
                }
                dispatch(companiesLoading(false));
            })
            .catch((e) => {
                console.log(e);
                dispatch(companiesLoading(false));
            });
    };
}

// export function setCategory(data) {
//     return (dispatch) => {
//         axios
//             .post(baseUrl + '/category/set', data, {
//                 headers: {
//                     authorization: Cookies.get('token'),
//                 },
//             })
//             .then((res) => {
//                 console.log('setCategory:', res);
//                 if (!res.data.error && res.data.data.length > 0) {
//                     dispatch(setCategories(res.data.data));
//                 }
//             });
//     };
// }
//
// export function updateCategory(data) {
//     return (dispatch) => {
//         axios
//             .post(baseUrl + '/category/update', data, {
//                 headers: {
//                     authorization: Cookies.get('token'),
//                 },
//             })
//             .then((res) => {
//                 console.log('updateCategory:', res);
//                 if (!res.data.error && res.data.data.length > 0) {
//                     dispatch(setCategories(res.data.data));
//                 }
//             });
//     };
// }
//
// export function changeCategoryStatus(id) {
//     return (dispatch) => {
//         axios
//             .post(baseUrl + '/category/changeStatus', id, {
//                 headers: {
//                     authorization: Cookies.get('token'),
//                 },
//             })
//             .then((res) => {
//                 if (!res.data.error && res.data.data.length > 0) {
//                     dispatch(setCategories(res.data.data));
//                 }
//             });
//     };
// }
/** Reducer **/
export const companiesTableData = createReducer({ companies: {}, isLoading: true }, (state, { value }) => ({
    [SET_COMPANIES]: () => {
        return { ...state, companies: value };
    },
    [COMPANIES_LOADING]: () => ({ ...state, isLoading: value }),
    // [UPDATE_EXAMPLE]: ()=> ({ ...state, ...value }),
    // [RESET_EXAMPLE]: ()=> initial,
}));
