import React, { useEffect } from 'react';
import { Form, Modal } from 'antd';

function ViewModal({ visible, viewData, onOk, onCancel }) {
    const {
        id,
        company_name,
        company_type,
        email,
        phone,
        legal_address,
        bank,
        tin,
        account_number,
        company_director,
        vat_type,
        brand_name,
        barcode,
    } = viewData;
    return (
        <Modal title={false} visible={visible} onOk={onOk} onCancel={onCancel}>
            <Form name="nest-messages">
                <Form.Item label="ID">{id}</Form.Item>
                <Form.Item label="Company Name">{company_name}</Form.Item>
                <Form.Item label="Type">{company_type}</Form.Item>
                <Form.Item label="Email">{email}</Form.Item>
                <Form.Item label="Phone">{phone}</Form.Item>
                <Form.Item label="Address">{legal_address}</Form.Item>
                <Form.Item label="Bank">{bank}</Form.Item>
                <Form.Item label="TIN">{tin}</Form.Item>
                <Form.Item label="Number">{account_number}</Form.Item>
                <Form.Item label="Director">{company_director}</Form.Item>
                <Form.Item label="VAT">{!!Number(vat_type) ? 'Paid' : 'Unpaid'}</Form.Item>
                <Form.Item label="Brand">{brand_name}</Form.Item>
                <Form.Item label="Barcode">{!!Number(barcode) ? 'Yes' : 'No'}</Form.Item>
            </Form>
        </Modal>
    );
}

export default ViewModal;
