import React from 'react';
import UniHeader from './Header/UniHeader';
import { Layout } from 'antd';
import LeftSidebar from './LeftSideBar/LeftSidebar';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import CategoriesTable from './MainContent/CategoriesTable/CategoriesTable';
import ProductsTable from './MainContent/ProductsTable/ProductsTable';
import OrdersTable from './MainContent/OrdersTable/OrdersTable';
import UsersTable from './MainContent/UsersTable/UsersTable';
import Reviews from './MainContent/ReviewsTable/ReviewsTable';
import UsersTableByID from './MainContent/UsersTable/UsersTableByID';
import ProductsTableByID from './MainContent/ProductsTable/ProductsTableByID';
import Dashboard from './MainContent/Dashboard';
import Banners from './MainContent/BannersTable/';
import GlobalSettings from './GlobalSettings/index';
import AdminUsers from './MainContent/AdminUsers/AdminUsersTable';
import GiftsTable from './MainContent/GiftsTable/GiftsTable';
import SingleOrderByID from './MainContent/OrdersTable/SingleOrderByID';
import CompaniesTable from './MainContent/CompaniesTable/CompaniesTable';
import MessagesTable from './MainContent/MessagesTable/';
import PartnershipTable from './MainContent/PartnershipTable/';

const { Content } = Layout;

function MainLayout() {
    const location = useLocation();
    const tabId = location.state?.tabId || 0;

    return (
        <>
            <UniHeader />
            <LeftSidebar />
            <div className="unipack-main">
                <Content style={{ padding: '5px', marginLeft: '200px', minHeight: 280 }}>
                    <div className="unipack-main-content">
                        <Route path={'/'} exact render={() => <Redirect to={'/dashboard'} />} />
                        <Route path={'/dashboard'} exact render={() => <Dashboard tabId={tabId} />} />
                        <Route path={'/categories'} exact render={() => <CategoriesTable tabId={tabId} />} />
                        <Route
                            path={'/products/:id'}
                            exact
                            render={(props) => <ProductsTableByID tabId={tabId} {...props} />}
                        />
                        <Route path={'/products'} exact render={() => <ProductsTable tabId={tabId} />} />
                        <Route
                            path={'/orders/:id'}
                            exact
                            render={(props) => <SingleOrderByID tabId={tabId} {...props} />}
                        />
                        <Route path={'/orders'} exact render={(props) => <OrdersTable tabId={tabId} {...props} />} />
                        <Route
                            path={'/users/:id'}
                            exact
                            render={(props) => <UsersTableByID tabId={tabId} {...props} />}
                        />
                        <Route path={'/users'} exact render={(props) => <UsersTable tabId={tabId} {...props} />} />
                        <Route path={'/global-settings'} exact render={() => <GlobalSettings tabId={tabId} />} />
                        <Route path={'/reviews'} exact render={() => <Reviews tabId={tabId} />} />
                        <Route path={'/banners/web'} exact render={(props) => <Banners tabId={tabId} {...props} />} />
                        <Route
                            path={'/banners/mobile'}
                            exact
                            render={(props) => <Banners tabId={tabId} {...props} />}
                        />
                        {/*<Route path={'/admin-users'} exact render={() => <AdminUsers tabId={tabId} />} />*/}
                        <Route path={'/gifts'} exact render={() => <GiftsTable tabId={tabId} />} />
                        <Route
                            path={'/companies'}
                            exact
                            render={(props) => <CompaniesTable tabId={tabId} {...props} />}
                        />
                        <Route
                            path={'/messages'}
                            exact
                            render={(props) => <MessagesTable tabId={tabId} {...props} />}
                        />
                        <Route
                            path={'/partnership'}
                            exact
                            render={(props) => <PartnershipTable tabId={tabId} {...props} />}
                        />
                    </div>
                </Content>
            </div>
        </>
    );
}

export default MainLayout;
