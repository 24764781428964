import React from 'react';
import { Statistic, Card, Row, Col, Typography, Tabs, DatePicker, Spin, Alert } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Bar from './bar';
import { TABLE_CONTENT_CRUDS } from '../../../constants/other';
const { Title } = Typography;
const { TabPane } = Tabs;

const statStyle = {
    fontSize: 20,
    color: 'black',
    width: '25%',
};
const gridStyle = {
    width: '50%',
};

class Dashboard extends React.Component {
    state = {
        salesType: 'all',
        notify: false,
        currentTabKey: '',
        rangePickerValue: moment(),
    };

    getDashboarddata = () => {
        TABLE_CONTENT_CRUDS[0].get({ year: moment().format('YYYY'), month: moment().format('M') });
    };

    componentDidMount() {
        this.getDashboarddata();

        this.interval = setInterval(this.getDashboarddata, 20000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    blockTitle = (title, level = 4) => {
        return <Title level={level}>{title}</Title>;
    };

    handleRangePickerChange = (rangePickerValue) => {
        this.setState(
            {
                rangePickerValue,
            },
            () => {
                TABLE_CONTENT_CRUDS[0].get({
                    year: moment(rangePickerValue).format('YYYY'),
                    month: moment(rangePickerValue).format('M'),
                });
            }
        );
    };
    render() {
        const { dashboard, notify } = this.props.dashboardData;
        const { orders, products, product_reviews, users, ordersByMonth } = dashboard;
        const isLoading = false;

        return (
            <>
                {isLoading ? (
                    <Spin />
                ) : (
                    <div className="site-statistic-demo-card" style={{ padding: 20, background: '#f0f2f5' }}>
                        {notify && <Alert message="Dashboard is updated" type="success" showIcon closable />}
                        <Row gutter={[48, 48]}>
                            <Col span={12} class={'gutter-row'}>
                                <Link to={'/users'}>
                                    <Card title={'Users'}>
                                        <Card.Grid style={gridStyle}>
                                            <Statistic
                                                title={this.blockTitle('Month')}
                                                style={statStyle}
                                                value={users.month}
                                            />
                                        </Card.Grid>
                                        <Card.Grid style={gridStyle}>
                                            <Statistic
                                                title={this.blockTitle('Week', 4)}
                                                style={statStyle}
                                                value={users.week}
                                                valueStyle={{ color: '#3f8600' }}
                                                prefix={<ArrowUpOutlined />}
                                            />
                                        </Card.Grid>
                                    </Card>
                                </Link>
                            </Col>
                            <Col span={12} class={'gutter-row'}>
                                <Link to={'/reviews'}>
                                    <Card title={'Reviews'}>
                                        <Card.Grid style={gridStyle}>
                                            <Statistic
                                                title={this.blockTitle('Month')}
                                                style={statStyle}
                                                value={product_reviews.month}
                                            />
                                        </Card.Grid>
                                        <Card.Grid style={gridStyle}>
                                            <Statistic
                                                title={this.blockTitle('Week', 4)}
                                                style={statStyle}
                                                value={product_reviews.week}
                                                valueStyle={{ color: '#3f8600' }}
                                                prefix={<ArrowUpOutlined />}
                                            />
                                        </Card.Grid>
                                    </Card>
                                </Link>
                            </Col>
                        </Row>
                        <Row gutter={[48, 48]}>
                            <Col span={12} class={'gutter-row'}>
                                <Link to={'/products'}>
                                    <Card title={'Products'}>
                                        <Card.Grid style={gridStyle}>
                                            <Statistic
                                                title={this.blockTitle('Month')}
                                                style={statStyle}
                                                value={products.month}
                                            />
                                        </Card.Grid>
                                        <Card.Grid style={gridStyle}>
                                            <Statistic
                                                title={this.blockTitle('Week', 4)}
                                                style={statStyle}
                                                value={products.week}
                                                valueStyle={{ color: '#3f8600' }}
                                                prefix={<ArrowUpOutlined />}
                                            />
                                        </Card.Grid>
                                    </Card>
                                </Link>
                            </Col>
                            <Col span={12} class={'gutter-row'}>
                                <Link to={'/orders'}>
                                    <Card title={'Orders'}>
                                        <Card.Grid style={gridStyle}>
                                            <Statistic
                                                title={this.blockTitle('Month')}
                                                style={statStyle}
                                                value={orders.month}
                                            />
                                        </Card.Grid>
                                        <Card.Grid style={gridStyle}>
                                            <Statistic
                                                title={this.blockTitle('Week', 4)}
                                                style={statStyle}
                                                value={orders.week}
                                                valueStyle={{ color: '#3f8600' }}
                                                prefix={<ArrowUpOutlined />}
                                            />
                                        </Card.Grid>
                                    </Card>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} class={'gutter-row'}>
                                <Card>
                                    <Tabs
                                        tabBarExtraContent={
                                            <div>
                                                <DatePicker
                                                    value={this.state.rangePickerValue}
                                                    picker="month"
                                                    onChange={this.handleRangePickerChange}
                                                    style={{
                                                        width: 256,
                                                    }}
                                                />
                                            </div>
                                        }
                                        defaultActiveKey={'ordersByMount'}
                                        size="large"
                                        tabBarStyle={{
                                            marginBottom: 24,
                                        }}
                                    >
                                        <TabPane tab={this.blockTitle('Orders By Days')} key="ordersByMount">
                                            <Bar height={295} title={this.blockTitle('')} data={ordersByMonth} />
                                        </TabPane>
                                    </Tabs>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = ({ dashboardData }) => ({ dashboardData });

export default connect(mapStateToProps)(Dashboard);
